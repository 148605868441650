
import Vue from 'vue';
import './store'
import { abilitiesPlugin } from '@casl/vue'
import   VuePolling  from 'vue-polling'

import vuetify from '../vuetify';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

import  abilityObj  from './utils/ability'
Vue.use(abilitiesPlugin, abilityObj)

Vue.use(VuePolling)

Vue.use(TiptapVuetifyPlugin, {
    vuetify, // same as "vuetify: vuetify"
    iconsGroup: 'mdi'
  })

import router from "@/router";
import './permission'
import './components'
import routes from './routes'

import './styles/index.scss';

import DefaultLayout from './layouts/Default.vue';
import NoSidebarLayout from './layouts/NoSidebar.vue';
import Vuecommerce from './Main.vue';
import VuecommerceGlobalItem from './GlobalItem.vue';

import vcitem from './directives/vcitem';
import vcimage from './directives/vcimage';
import vcdescription from './directives/vcdescription';

Vue.component('vuecommerce-default-layout', DefaultLayout)
Vue.component('vuecommerce-no-sidebar-layout', NoSidebarLayout)
Vue.component('vuecommerce-global-item', VuecommerceGlobalItem)
Vue.component('vuecommerce', Vuecommerce)

Vue.directive('vcitem', vcitem)
Vue.directive('vcimage', vcimage.image)
Vue.directive('vcbgimage', vcimage.bgimage)
Vue.directive('vcdescription', vcdescription)

router.addRoutes(routes);

export default {
    component: Vuecommerce
}
