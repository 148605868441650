import axios from 'axios'
// import {
//   MessageBox,
//   Message
// } from 'element-ui'
// import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'
// import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // }
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (config.data) {
      // This is to prevent axios remove undefined fields
      Object.keys(config.data).forEach(function (key) {
        if (config.data[key] === undefined) config.data[key] = null
      })
    }
    const token = getToken()
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + token
      // config.headers['x-query-debug'] = true
    }
    return config
  },
  error => {
    // do something with request error
    console.error('DEBUG: ' + error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    const res = response.data

    if (response.status !== 200) {
      return Promise.reject(res.message || 'error')
    }
    return res
  },
  error => {
    if (error.response && error.response.status === 401) { // Unauthorized
      //store.dispatch('user/resetToken')
      router.push('/vuecommerce/login')
    }
    return Promise.reject(error)
  })

export default service
