<template>
    <div class="wrapper ecommerce-page" v-vcitem="page">
       


        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="z-index:1;filter: brightness(70%);"
                  v-vcbgimage:image1="group">
            </parallax>
           
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h1  class="title">{{group.title}}</h1>
                    </div>
                </div>
            </div>
        </div>


        <div class="main">
            <div class="section">
                <div class="container">
                    <h2 class="section-title">{{page.subtitle}}</h2>
                    <div class="row">
                        <!--div class="col-md-3">
                            <div class="collapse-panel">
                                <div class="card-body">
                                    <div class="card card-refine card-plain">
                                        <h4 class="card-title">
                                            Refine
                                            <button class="btn btn-default btn-icon btn-neutral pull-right"
                                                    rel="tooltip" title="Reset Filter">
                                                <i class="arrows-1_refresh-69 now-ui-icons"></i>
                                            </button>
                                        </h4>
                                        <collapse>
                                            <collapse-item no-icon class="card-header">
                                                <h6 class="mb-0 text-primary" slot="title">
                                                    Price Range
                                                    <i class="now-ui-icons arrows-1_minimal-down"></i>
                                                </h6>
                                                <span class="price-left pull-left">€{{filters.priceRange[0]}}</span>
                                                <span class="price-right pull-right">€{{filters.priceRange[1]}}</span>
                                                <div class="clearfix"></div>
                                                <slider id="slider-refine"
                                                        class="slider-refine"
                                                        v-model="filters.priceRange"
                                                        :range="{min: 0, max: 900}"
                                                        :connect="true">
                                                </slider>
                                            </collapse-item>
                                            <collapse-item no-icon class="card-header">
                                                <h6 class="mb-0 text-primary" slot="title">
                                                    Clothing
                                                    <i class="now-ui-icons arrows-1_minimal-down"></i>
                                                </h6>
                                                <n-checkbox v-for="clothing in filters.clothingTypes"
                                                            v-model="clothing.value"
                                                            :key="clothing.label">
                                                    {{clothing.label}}
                                                </n-checkbox>
                                            </collapse-item>
                                            <collapse-item no-icon class="card-header">
                                                <h6 class="mb-0 text-primary" slot="title">
                                                    Designer
                                                    <i class="now-ui-icons arrows-1_minimal-down"></i>
                                                </h6>
                                                <n-checkbox v-for="desginer in filters.designerTypes"
                                                            v-model="desginer.value"
                                                            :key="desginer.label">
                                                    {{desginer.label}}
                                                </n-checkbox>
                                            </collapse-item>
                                            <collapse-item no-icon class="card-header">
                                                <h6 class="mb-0 text-primary" slot="title">
                                                    Colour
                                                    <i class="now-ui-icons arrows-1_minimal-down"></i>
                                                </h6>
                                                <n-checkbox v-for="color in filters.colourTypes"
                                                            v-model="color.value"
                                                            :key="color.label">
                                                    {{color.label}}
                                                </n-checkbox>
                                            </collapse-item>
                                        </collapse>
                                    </div>
                                </div>
                            </div>
                        </div-->
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-6"
                                     v-for="item in items"
                                     :key="item.id">
                                      <a :href="'/#/product/' + item.id" >

                                    <card type="product" plain v-vcitem="item" style="text-align:center;height:100%">
                                       
                                        <img slot="image" height="260px" style="text-align:center;object-fit: cover" :src="item.image" v-vcimage:image="item">

                                            <h4 class="card-title">{{item.title}}</h4>
                                            <p class="card-description" v-html="item.description" style="text-align:left;margin-left:20px">
                                            </p>

                                        <div class="card-footer" style="text-align:left;margin-left:20px">
                                            <div class="price-container">
                                                <span class="price"> &euro; {{item.price}} {{item.price_descr}}</span>
                                            </div>
                                            <!--el-tooltip effect="light" content="Remove from wishlist" placement="top">
                                                <n-button type="danger" icon round class="pull-right btn-neutral">
                                                    <i class="now-ui-icons ui-2_favourite-28"></i>
                                                </n-button>
                                            </el-tooltip-->
                                        </div>
                                       
                                    </card>
                                    </a>
                                </div>
                                <!--div class="col-md-12 ml-auto mr-auto">
                                    <n-button type="primary" round>Load more...</n-button>
                                </div-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<hr>
            <div class="section">
                <div class="container">
                    <h2 class="section-title">{{group.title2}}</h2>
                    <div class="row">
                        <div class="col-md-12" v-html="group.description1" v-vcdescription:description1="group">

                        </div>
                    </div>
                </div>
            </div>


            <!-- <div class="section">
                <div class="container">
                    <h2 class="section-title">Latest Offers</h2>
                    <div class="row">
                        <div class="col-md-4">
                            <card type="product" plain>
                                <img slot="image" src="img/saint-laurent1.jpg" alt="">
                                <h4 class="card-title">
                                    <a href="#pablo">Saint Laurent</a>
                                </h4>
                                <p class="card-description">The structured shoulders and sleek detailing ensure a
                                    sharp silhouette. Team it with a silk pocket square and leather loafers.</p>
                                <div class="card-footer">
                                    <div class="price-container">
                                        <span class="price price-old"> &euro;1,430</span>
                                        <span class="price price-new"> &euro;743</span>
                                    </div>
                                    <div class="stats stats-right">
                                        <el-tooltip content="Saved to Wishlist" effect="light" placement="top">
                                            <n-button type="neutral" icon>
                                                <i class="now-ui-icons ui-2_favourite-28"></i>
                                            </n-button>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </card>
                        </div>
                        <div class="col-md-4">
                            <card type="product" plain>
                                <img slot="image" src="img/saint-laurent1.jpg" alt="">
                                <h4 class="card-title">
                                    <a href="#pablo">Saint Laurent</a>
                                </h4>
                                <p class="card-description">The structured shoulders and sleek detailing ensure a
                                    sharp silhouette. Team it with a silk pocket square and leather loafers.</p>
                                <div class="card-footer">
                                    <div class="price-container">
                                        <span class="price price-old"> &euro;1,430</span>
                                        <span class="price price-new"> &euro;743</span>
                                    </div>
                                    <div class="stats stats-right">
                                        <el-tooltip content="Saved to Wishlist" effect="light" placement="top">
                                            <n-button type="neutral" icon>
                                                <i class="now-ui-icons ui-2_favourite-28"></i>
                                            </n-button>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </card>
                        </div>
                        <div class="col-md-4">
                            <card type="product" plain>
                                <img slot="image" src="img/gucci.jpg" alt="">
                                <h4 class="card-title">
                                    <a href="#pablo">Gucci</a>
                                </h4>
                                <p class="card-description">The smooth woven-wool is water resistant to ensure you
                                    stay pristine after a long-haul flight. Cut in a trim yet comfortable regular
                                    fit.</p>
                                <div class="card-footer">
                                    <div class="price-container">
                                        <span class="price price-old"> &euro;2,430</span>
                                        <span class="price price-new">&euro;890</span>
                                    </div>
                                    <div class="stats stats-right">
                                        <el-tooltip content="Saved to Wishlist" effect="light" placement="top">
                                            <n-button type="neutral" icon>
                                                <i class="now-ui-icons ui-2_favourite-28"></i>
                                            </n-button>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div> -->
          
            <!--div class="subscribe-line subscribe-line-image" style="background-image: url('img/bg43.jpg')">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="text-center">
                                <h4 class="title">Subscribe to our Newsletter</h4>
                                <p class="description">
                                    Join our newsletter and get news in your inbox every week! We hate spam too, so no
                                    worries about this.
                                </p>
                            </div>
                            <card type="raised" class="card-form-horizontal">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <fg-input
                                                placeholder="Your Email..."
                                                addon-left-icon="now-ui-icons ui-1_email-85">
                                        </fg-input>
                                    </div>
                                    <div class="col-sm-4">
                                        <n-button type="primary" round block>
                                            Subscribe
                                        </n-button>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div-->

            <footer class="footer footer-big footer-white">
                <div class="container">
                    <hr />
                    <div class="copyright">
                        Copyright &copy;
                        {{year}} Depalop All Rights Reserved.
                    </div>
                </div>
            </footer>

        </div>

       

    </div>
</template>
<script>
  import { Card, Button, InfoSection, FormGroupInput, Checkbox, Collapse, CollapseItem, Slider } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui'
  const { getList } = require('@/api/base_entity')


  export default {
    name: 'ecommerce-page',
    bodyClass: 'ecommerce-page',
    components: {
      Card,
      InfoSection,
      Collapse,
      CollapseItem,
      Slider,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip,
    },
    mounted() {
        this.$root.$on('vuecommerce-update-item', this.updateItems)
        this.parent_id = this.$route.params.group_id
        this.updateItems()
    },
    destroyed() {
        this.$root.$off('vuecommerce-update-item')
    },
    methods: {
        updateItems() {
            getList('item', {type:'article', parent_id:this.parent_id}, {sortBy:'weight', rowsPerPage: -1}).then( data => {
                  this.items = data.data
            })
            getList('item', {type:'page', name:'category'}, {sortBy:'weight', rowsPerPage: -1}).then( data => {
                  this.page = data.data[0]
            })
            getList('item', {type:'group', id: this.parent_id}, {sortBy:'weight', rowsPerPage: -1}).then( data => {
                  this.group = data.data[0] || {}
            })
        },
    },
    data() {
      return {
        year: new Date().getFullYear(),
        items: [],
        page: {},
        group: {},
        parent_id: null,
        widgets: false,
        contract: {}
      }
    }
  }
</script>
<style>
</style>
