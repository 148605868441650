// import styles from '@/styles/material-dashboard/_variables.scss'



export default {
  primary: "#306293",
  secondary: "#30b1dc",
  tertiary: "#2199c2",
  accent: "#e91e63",
  success: "#5794D0",
  info: "#2196F3",
  warning: "#FB8C00",
  danger: "#FB8C00",
  error: "#FF5252"
}

// export default {
//   primary: styles.primary,
//   secondary: styles.secondary,
//   tertiary: styles.tertiary,
//   accent: styles.accent,
//   error: styles.error,
//   info: styles.info,
//   success: styles.success,
//   warning: styles.warning,
// }
