<template>
  <v-dialog v-model="value" persistent max-width="600px">
        <material-card
          color="orange"
          title="VueCommerce - Change Password"
          text="Choose a new PASSWORD"
        >
          <v-form ref="form" v-model="validForm">
            <v-container py-0>
              <v-layout wrap>
              

                <v-flex xs12 md12>
                  <v-text-field
                    autocomplete="false"
                    v-model="changeForm.oldpass"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      passwordType0 == 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="showPwd0"
                    name="OLD password"
                    label="OLD Password"
                    id="old_password"
                    :type="passwordType0"
                    required
                    :rules="[validators.password]">
                  </v-text-field>
                </v-flex>

              
                <v-flex xs12 md12>
                  <v-text-field
                    autocomplete="false"
                    v-model="changeForm.newpass"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      passwordType1 == 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="showPwd1"
                    name="NEW password"
                    label="NEW Password"
                    id="password"
                    :type="passwordType1"
                    required
                    :rules="[validators.password]"
                  >
                  </v-text-field>
                </v-flex>


                <v-flex xs12 md12>
                  <v-text-field
                    autocomplete="new-password"
                    v-model="changeForm.confirmpass"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      passwordType2 == 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="showPwd2"
                    name="Confirm NEW password"
                    label="Confirm NEW Password"
                    id="password"
                    :type="passwordType2"
                    required
                    :rules="[v => validators.confirm_password(v, changeForm.newpass)]"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 text-right>
                  
                  <v-btn
                    color="primary"
                    class="mx-1 font-weight-light"
                    @click="close()">Close</v-btn>

                  <v-btn
                    color="orange"
                    class="mx-1 font-weight-light"
                    @click="confirm_change"
                    :disabled="!validForm || loading"
                    :loading="loading"
                    >Confirm</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
  </v-dialog>
</template>

<script>
import { changePass } from "../../api/auth"
import validators from "../../utils/validators"

export default {
  props: {
    value: { type: Boolean, default: true }
  },
  data: () => ({
    changeForm: {},
    validForm: true,
    loading: false,
    passwordType0: 'password',
    passwordType1: 'password',
    passwordType2: 'password',
    validators
  }),
  watch: {
    value(v) {
      if (v && this.$refs.form) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    close() {
      this.$emit("input", false)
    },
    confirm_change() {
      
      changePass(this.changeForm)
        .then(() => {
          this.$root.$emit("openSnackbar", {
            message: "The new password has been stored correctly!",
            color: "success"
          })
          this.$emit("input", false)
        })
        .catch( e => {
          const error = e.response.data.message;
          this.$root.$emit("openSnackbar", {
            message: "An error occurred during the new password confirmation : " + error,
            color: "error"
          })
        })
    },
    showPwd0 () {
      this.passwordType0 = this.passwordType0 === 'password' ? 'text' : 'password'
    },
    showPwd1 () {
      this.passwordType1 = this.passwordType1 === 'password' ? 'text' : 'password'
    },
    showPwd2 () {
      this.passwordType2 = this.passwordType2 === 'password' ? 'text' : 'password'
    },
  },
 
}
</script>
