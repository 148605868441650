<template>
    <div class="wrapper ecommerce-page">
        
       
         <div class="page-header page-header-small">
            <parallax class="page-header-image" :style="'z-index:1'"  v-vcbgimage:image1="page" >
            </parallax>
        </div>

        <div class="main">
            <div class="contact-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 ml-auto mr-auto"  v-vcitem="contents['send-message']">
                            <h2 class="title">{{contents['send-message'].title}}</h2>
                            <p class="description" v-html="contents['send-message'].description" v-vcdescription:description="contents['send-message']">
                            </p>
                            <form role="form" ref="form" id="contact-form" method="post" v-vcitem="contents['form']">
                                <label>{{contents['form'].title}}</label>
                                <fg-input
                                        _placeholder="Your Name..."
                                        v-model="form.firstName"
                                        addon-left-icon="now-ui-icons users_circle-08">
                                </fg-input>

                                <label>{{contents['form'].title1}}</label>
                                <fg-input
                                        _placeholder="Email Here..."
                                        v-model="form.email"
                                        addon-left-icon="now-ui-icons users_circle-08">
                                </fg-input>
                                <label>{{contents['form'].title2}}</label>

                                <fg-input
                                        _placeholder="Number Here..."
                                        v-model="form.phone"
                                        addon-left-icon="now-ui-icons tech_mobile">
                                </fg-input>

                                <div class="form-group">
                                    <label>{{contents['form'].title3}}</label>
                                    <textarea name="message" class="form-control" id="message" rows="6" v-model="form.message"></textarea>
                                </div>
                                <div class="submit text-center">
                                    <n-button @click="contact" :disabled="!(form.message && form.email && form.firstName && form.phone)" type="primary" round>{{contents['form'].title4}}</n-button>
                                </div>
                            </form>
                        </div>
                        
                        <div class="col-md-5 ml-auto mr-auto" v-vcitem="contents['info']">
                            <info-section type="primary" 
                                          class="mt-5"
                                          icon="now-ui-icons location_pin">
                                <h4 class="info-title">{{contents['info'].title}}</h4>
                                <div v-html="contents['info'].description" v-vcdescription:description="contents['info']"> </div>
                            </info-section>

                            <info-section type="primary" v-vcitem="contents['give-ring']"
                                          icon="now-ui-icons tech_mobile">
                                <h4 class="info-title">{{contents['info'].title1}}</h4>
                                <div v-html="contents['info'].description1" v-vcdescription:description1="contents['info']"> </div>
                            </info-section>

                            <info-section type="primary" v-vcitem="contents['legal-info']"
                                          icon="business_briefcase-24 now-ui-icons">
                                <h4 class="info-title">{{contents['info'].title2}}</h4>
                                <div v-html="contents['info'].description2" v-vcdescription:description2="contents['info']"> </div>
                            </info-section>
                        </div>


                    </div>
                </div>
            </div>
        </div>
   
        <gmap-map
                id="map"
                class="big-map"
                :center="center"
                :zoom="12"
                map-type-id="terrain">
            <gmap-marker :position="center">
            </gmap-marker>
        </gmap-map>
    </div>
</template>
<script>
  import { Button, InfoSection, FormGroupInput } from '@/components';
  import {API_KEY} from '@/constants'
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  
  const { getList, contact } = require('@/api/base_entity')

  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  });
  export default {
    name: 'contact-us',
    bodyClass: 'contact-page',
    components: {
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
    },
    data() {
      return {
        year: new Date().getFullYear(),
        page: {},
        footer: {},
        contents: {'send-message':{},'form':{},'info':{},},
        form: {
          firstName: null,
          email: null,
          phone: null,
          message: null
        },
        center: {
          lat: 38.7318708,
          lng: 1.416141
        },
        options: {
        }
      }
    },
    mounted() {
        this.$root.$on('vuecommerce-update-item', this.updateItems)
        this.updateItems()
    },
    destroyed() {
        this.$root.$off('vuecommerce-update-item')
    },
    methods: {
        async updateItems() {

             var ret = await getList('item', {type:'page', name:'contact-us'})
             this.page = ret.data[0]

             var ret = await getList('item', {type:'content', parent_id: this.page.id})
             var elab = {}
             ret.data.forEach( el => {
                 elab[el.name] = el 
             })
             this.contents = elab
        },
        contact() {
         
          // this.$refs.form.reset();
          contact(this.form).then( ret => {
             this.form = {
              firstName: null,
              email: null,
              phone: null,
              message: null
            }
            alert(this.contents['form'].title5)
          })
        }
    }
  }
</script>
<style>
</style>
