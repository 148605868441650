<template>
  <material-card
    v-bind="$attrs"
    class="v-card--material-chart"
    v-on="$listeners"
  >
    <chartist
      :data="data"
      :event-handlers="eventHandlers"
      :options="options"
      :ratio="ratio"
      :responsive-options="responsiveOptions"
      :type="type"
    />

    <slot />

    <slot
      slot="actions"
      name="actions"
    />
  </material-card>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    eventHandlers: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Object,
      default: () => ({})
    },
    ratio: {
      type: String,
      default: undefined
    },
    responsiveOptions: {
      type: Array,
      default: () => ([])
    },
    type: {
      type: String,
      required: true,
      validator: v => ['Bar', 'Line', 'Pie'].includes(v)
    }
  }
}
</script>

<style lang="scss">
  .v-card--material-chart {
      .ct-label {
        color: black;
        opacity: 1;
        font-size: 0.975rem;
        font-weight: 100;
      }

      .ct-bar {
        stroke-width: 2.4vw;
      }

      .ct-series-a .ct-bar {
        stroke: lightblue;
      }
      .ct-series-b .ct-bar {
        stroke: orangered;
        opacity: 0.9;
      }
  }
</style>
