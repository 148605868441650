<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>


      <v-flex md2>
        <v-select clearable placeholder="Field" v-model="search['lang']" :items="['IT' ,'EN', 'ES', 'FR', 'DE']"/>
      </v-flex>

      <v-flex md3>
        <v-text-field clearable multiple placeholder="Field" v-model="search['field']" />
      </v-flex>

       <v-flex md2>
          <v-btn :disabled="disableTranslateMenu" @click="launchTranslate">Translate</v-btn>
        </v-flex>

      <v-flex md12>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th  v-for="i in filteredHheaders" :key="i.value" class="text-left">
                    {{i.value}}
                  </th>
                </tr>
                <tr>
                  <th  v-for="i in filteredHheaders" :key="i.value" class="text-left">
                     <div v-if="['id', 'type', 'name'].includes(i.value)">
                      <v-text-field v-model="search[i.value]" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in filteredItems"
                  :key="item.id"
                >
                  <td v-for="i in filteredHheaders" :key="i.value">
                   
                    <div v-if="['id', 'type', 'name'].includes(i.value)">
                      <b>{{item[i.value]}}</b>
                    </div>
                    <div v-else>
                      <v-btn v-if="item[i.value] == null" @click="translate(item, i.value)" :disabled="!hasMain(item, i.value)">
                        <v-icon color="red">mdi-plus</v-icon>
                      </v-btn>

                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                          <v-btn  v-on="on" @click="translate(item, i.value)" :disabled="!hasMain(item, i.value)">
                            <v-icon color="green">mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>{{item[i.value]}}</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


      </v-flex>
    </v-layout>

    <app-translate-modal v-model="openTranslateSimple" :translate="selectedItem" :fieldLang="selectedFieldLang" @saved="saved"/>
    <app-translate-html-modal v-model="openTranslateHtml" :translate="selectedItem" :fieldLang="selectedFieldLang" @saved="saved"/>

  </v-container>
</template>

<script>

const { getList, get } = require('../../api/base_entity')

export default {
  data () {
    return {
      openTranslateSimple: false,
      openTranslateHtml: false,
      selectedFieldLang: null,
      selectedItem: {},
      filters: {},
      pagination: {},
      items: [],
      headers: [],
      search: {'id':'', 'type':'', 'name':''},
      disableTranslateMenu: false
     }
  },
  mounted() {
    this.getList();
  },
  computed: {
    filteredItems() {
        const ret = this.items.filter( el => {
          if (!this.search['name'] && !this.search['id'] && !this.search['type']) return true
          return (this.search['name'] && el.name.indexOf(this.search['name']) != -1) || ( this.search['type'] && el.type.indexOf(this.search['type']) != -1) || (this.search['id'] && el.id == this.search['id'])
        })
        return ret
    },
    filteredHheaders() {
      const ret = this.headers.filter( el => {
          if (['id', 'type', 'name'].includes(el.value)) return true
          const lang = el.value.split('_')[1]
          if (!this.search['field'] && !this.search['lang']) return true
          const filterField = (this.search['field'] && el.value.indexOf(this.search['field']) != -1) 
          const filterLang = (this.search['lang'] && lang == this.search['lang'])
          return (this.search['field'] ? filterField : true ) && (this.search['lang'] ? filterLang : true )
        })
        return ret
    }
  },
  methods: {
    launchTranslate() {
      this.disableTranslateMenu = true
      get('/translateall')
    },
    isHtml(field_lang) {
      return field_lang.split('_')[0].indexOf("description") == 0
    },
    async getList () {
      this.items = await getList('translate')
      const headers = Object.keys(this.items[0])
      this.headers = headers.filter(el => {
        return el.indexOf("_") != -1 || ['id', 'type', 'name'].includes(el)
      }).map( el => {
          return { text: el, value: el, sortable: false }
      })
      // return { data: data.data, tot: data.tot }
    },
    
    hasMain(item, field_lang) {
      return item[field_lang.split("_")[0]] ? true : false
    },
    saved() {
      this.getList()
      this.openTranslateSimple = false
      this.openTranslateHtml = false
    },
    translate(item, field_lang) {

      this.selectedItem = item
      this.selectedFieldLang = field_lang

      if (this.isHtml(field_lang)) {
        this.openTranslateHtml = true
      } else {
        this.openTranslateSimple = true
      }

     
      
    }
  }
}
</script>
