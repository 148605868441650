<template>
  <v-dialog  v-model="isOpen" persistent max-width="900px">
    <material-card :title="title">
      <v-form ref="form">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
           
              <tiptap-vuetify
                v-model="content"
                :extensions="extensions" />

            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <v-flex xs12 text-right>
        


        <v-btn
          class="mx-1 font-weight-light"
          color="primary"
          @click="close()">Cancel</v-btn>


        <v-btn
          v-if="$can('upsert', entityName)"
          class="mx-1 white--text"
          color="orange"
          @click="clear()"> Clear</v-btn>



        <v-btn class="mx-1 font-weight-light white--text" color="purple" @click="save()">Save</v-btn>


      </v-flex>
    </material-card>
  </v-dialog>
</template>

<script>


const { upsert, getList, getDetail } = require('../../api/base_entity')


import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  inheritAttrs: false,
  props: {
    open: { type: Boolean, default: false },
    data: { type: Object, default: {} }
  },
  data() {
    return {
      entityName: 'item',
      item: {},
      content: '',
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  mounted() {},
  watch: {
     open (v) {
        this.getData()
    }
  },
  computed: {
    isOpen () {
      return this.open
    },
    title() {
      return 'Edit the description field : ';
    },
    // content() {
    //   return this.item[this.data.field_name]
    // }
  },
  methods: {
    getData () {
      getDetail('item', { id: this.data.id }).then( data => {
        this.item = data
        this.content = this.item[this.data.field_name]
      })
    },
   close() {
      this.$emit("close");
    },
    save() {
      const data = {
        id: this.data.id,
        [this.data.field_name] : this.content
      }
      upsert('item', data).then( ret => {
        const data = {
          field_name: this.data.field_name,
          item: ret.data
        }
        // this.$root.$emit('vuecommerce-update-image', data)
        this.$root.$emit('refreshLoaedItem', data)
        this.$emit('changedDescription', data)
        this.close()
      })
    },
    clear() {
      this.content = null
      this.save()
    },
  }
};
</script>

<style>
#core-view {
  padding-bottom: 100px;
}

.tiptap-vuetify-editor .ProseMirror {
  min-height: 300px;
}
</style>
