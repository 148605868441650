import startCase from 'lodash/startCase'

/**
 * APP MENU
 * {
 *   `entity`  used to check if current user has access to this
 *   `to`      routing path (default to /entity)
 *   `icon`    icon to shown in menu
 *   `text`    label to show in menu (default: ucfirst(entity))
 * }
 */
const menu = [
  {
    entity: 'dashboard',
    to: '/vuecommerce',
    icon: 'mdi-view-dashboard',
    text: 'Dashboard'
  },
  // {
  //   entity: 'Settings',
  //   to: '/vuecommerce/settings',
  //   icon: 'mdi-cog',
  //   text: 'Settings'
  // },
  {
    entity: 'Accounts',
    to: '/vuecommerce/account',
    icon: 'mdi-account',
    text: 'Accounts'
  },
  {
    entity: 'Pages',
    to: '/vuecommerce/page',
    icon: 'mdi-book-open-page-variant',
    text: 'Pages'
  },
  {
    entity: 'Content',
    to: '/vuecommerce/content',
    icon: 'mdi-table-of-contents',
    text: 'Content'
  },
  {
    entity: 'Groups',
    to: '/vuecommerce/group',
    icon: 'mdi-group',
    text: 'Groups'
  },
  {
    entity: 'Articles',
    to: '/vuecommerce/article',
    icon: 'mdi-tag',
    text: 'Articles'
  },
  {
    entity: 'Translate',
    to: '/vuecommerce/translate',
    icon: 'mdi-translate',
    text: 'Translate'
  },
  {
    entity: 'Orders',
    to: '/vuecommerce/order',
    icon: 'mdi-order-bool-ascending-variant',
    text: 'Orders'
  },
  {
    to: '/vuecommerce/preview',
    icon: 'mdi-play-circle',
    text: 'Live'
  },
  // {
  //   entity: 'entities',
  //   type: 'core-drawer-group',
  //   text: 'Entities',
  //   icon: 'mdi-account-group',
  //   links: [
  //     {
  //       entity: 'site',
  //       icon: 'mdi-home',
  //       text: 'Sites'
  //     },
  //     {
  //       entity: 'account',
  //       icon: 'mdi-account',
  //       text: 'Accounts'
  //     },
  //     {
  //       entity: 'provider',
  //       icon: 'mdi-domain',
  //       text: 'Providers'
  //     },
  //     {
  //       entity: 'pharmacy',
  //       icon: 'mdi-pharmacy',
  //       text: 'Pharmacies'
  //     },
  //     {
  //       entity: 'storage',
  //       icon: 'mdi-storefront',
  //       text: 'Storage'
  //     },
  //     {
  //       entity: 'disposal',
  //       icon: 'mdi-delete-restore',
  //       text: 'Disposal'
  //     },
  //   ]
  // },
  // {
  //   entity: 'protocol_menu',
  //   icon: 'mdi-clipboard-text',
  //   text: 'Protocols',
  //   type: 'core-drawer-group',
  //   links:[
  //     {
  //       entity: 'protocol',
  //       action: 'create',
  //       to: '/protocol/new',
  //       icon: 'mdi-plus',
  //       text: 'Create',
  //     },      
  //     {
  //       entity: 'protocol',
  //       to: '/protocol',
  //       icon: 'mdi-clipboard-text',
  //       text: 'List'
  //     },
  //     {
  //       entity: 'request',
  //       to: '/request',
  //       icon: 'mdi-clipboard-text',
  //       text: 'Request'
  //     },
  //   ]
  // },
  // {
  //   entity: 'siterequest',
  //   icon: 'mdi-clipboard-text',
  //   text: 'Site Requests',
  //   type: 'core-drawer-group',
  //   links:[
  //     {
  //       entity: 'siterequest',
  //       action: 'create',
  //       icon: 'mdi-plus',
  //       text: 'Create',
  //       to: '/siterequest/new'
  //     },
  //     {
  //       entity: 'siterequest',
  //       icon: 'mdi-clipboard-text',
  //       text: 'List',
  //       to: '/siterequest'
  //     }
  //   ]
  // },
  // {
  //   entity: 'returned',
  //   icon: 'mdi-keyboard-return',
  //   text: 'Return',
  //   type: 'core-drawer-group',
  //   links:[
  //     {
  //       entity: 'returned',
  //       action: 'create',
  //       icon: 'mdi-plus',
  //       text: 'Create',
  //       to: '/returned/new'
  //     },
  //     {
  //       entity: 'returned',
  //       icon: 'mdi-clipboard-text',
  //       text: 'List',
  //       to: '/returned'
  //     }
  //   ]
  // },
  // {
  //   entity: 'disposal',
  //   icon: 'mdi-delete-restore',
  //   text: 'Destroy',
  //   type: 'core-drawer-group',
  //   links:[
  //     {
  //       entity: 'disposal',
  //       action: 'create',
  //       icon: 'mdi-plus',
  //       text: 'Create',
  //       to: '/disposed/new'
  //     },
  //     {
  //       entity: 'disposal',
  //       icon: 'mdi-clipboard-text',
  //       text: 'List',
  //       to: '/disposed'
  //     }
  //   ]
  // },
  // {
  //   entity: 'report',
  //   icon: 'mdi-chart-bar',
  //   text: 'Report',
  //   type: 'core-drawer-group',
  //   links:[
  //     {
  //       entity: 'report',
  //       action: 'create',
  //       icon: 'mdi-store',
  //       text: 'Available',
  //       to: '/report/available'
  //     },
  //     {
  //       entity: 'report',
  //       icon: 'mdi-file-excel',
  //       text: 'XLS',
  //       to: '/report/xls'
  //     },
  //     // {
  //     //   entity: 'return',
  //     //   icon: 'mdi-clipboard-text',
  //     //   text: 'List',
  //     //   to: '/return'
  //     // }
  //   ]
  // },
  // {
  //   entity: 'estimate',
  //   icon: 'mdi-calculator-variant-outline',
  //   text: 'Estimate',
  //   type: 'core-drawer-group',
  //   links:[
  //     {
  //       entity: 'estimate',
  //       action: 'create',
  //       icon: 'mdi-plus',
  //       text: 'Create',
  //       to: '/estimate'
  //     },
  //     {
  //       entity: 'estimate',
  //       icon: 'mdi-clipboard-text',
  //       text: 'List',
  //       to: '/estimates'
  //     }
  //   ]
  // }      
]

export default menu.map(item => ({
  type: item.type || 'core-drawer-link',
  entity: item.entity,
  action: item.action || 'read',
  to: item.to || `/${item.entity}`,
  icon: item.icon,
  text: item.text || startCase(item.entity),
  links: item.links ?
    item.links.map(l => ({
      entity: l.entity,
      action: l.action || 'read',
      to: l.to || `/${l.entity}`,
      icon: l.icon,
      text: l.text || startCase(l.entity),      
    })) : []
}))
