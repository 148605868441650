import axios from 'axios'
import { getToken } from '../utils/auth'
import router from '@/router'
import store from '../store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.data) {
      // This is to prevent axios remove undefined fields
      Object.keys(config.data).forEach(function (key) {
        if (config.data[key] === undefined) config.data[key] = null
      })
    }
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  
  response => {
    const res = response.data
    if (response.status !== 200) {
      return Promise.reject(res.message || 'error')
    }
    return res
  },
  error => {
    if (error.response && error.response.status === 401) { // Unauthorized
      // @TODO RESET TOKEN
      store.dispatch('user/resetToken')
      router.push('/vuecommerce/login')
    }
    return Promise.reject(error)
  })

export default service
