var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.field.type!=="hidden"),expression:"field.type!==\"hidden\""}],class:_vm.select && 'pa-0 ma-0',attrs:{"xs12":"","sm6":"","md1":_vm.field.micro,"md2":_vm.field.mini,"md3":_vm.field.slim,"md4":_vm.field.small,"md12":_vm.field.full,"md6":!_vm.field.small && !_vm.field.full}},[(_vm.field.type === 'text' || _vm.field.type === 'number' || !_vm.field.type)?_c('v-text-field',{attrs:{"value":_vm.value,"rules":!_vm.readonly ? _vm.rules : [],"label":_vm.field.text,"readonly":_vm.readonly,"disabled":_vm.readonly},on:{"input":_vm.updateValue}}):_vm._e(),(_vm.field.type === 'list')?_c('v-select',{attrs:{"disabled":_vm.readonly,"value":_vm.value,"items":_vm.computedList(),"rules":!_vm.readonly ? _vm.rules : [],"label":_vm.field.text,"clearable":"","md4":""},on:{"input":_vm.updateValue}}):_vm._e(),(_vm.field.type === 'autocomplete')?_c('v-autocomplete',{class:this.select ? 'ma-0 pa-0' : '',attrs:{"disabled":_vm.readonly,"value":_vm.value,"items":_vm.items,"rules":!_vm.readonly ? _vm.rules : [],"multiple":_vm.field.multiple,"chips":_vm.field.multiple,"small-chips":_vm.field.multiple,"deletable-chips":"","no-filter":"","loading":_vm.loading,"label":!this.select ? _vm.field.text : '',"search-input":_vm.search,"placeholder":_vm.field.placeholder || ("Choose a " + (_vm.field.text) + "..."),"color":"green","hide-no-data":"","item-text":_vm.field.title || 'name',"item-value":_vm.field.id || 'id',"prepend-icon":!this.select ? 'mdi-database-search' : '',"md4":"","clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.updateValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{attrs:{"two-line":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.autocompleteTitle(item))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.autocompleteSubtitle(item))}})],1)]}}],null,false,3678087455)}):_vm._e(),(_vm.field.type === 'combobox')?_c('v-combobox',{attrs:{"disabled":_vm.readonly,"value":_vm.value,"items":_vm.items,"rules":!_vm.readonly ? _vm.rules : [],"loading":_vm.loading,"label":_vm.field.text,"placeholder":("Choose a " + (_vm.field.text) + "..."),"color":"green","hide-no-data":"","item-text":_vm.field.title || 'name',"item-value":_vm.field.id || 'id',"prepend-icon":"mdi-database-search","md4":"","clearable":""},on:{"change":_vm.updateValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{attrs:{"two-line":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.autocompleteTitle(item))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.autocompleteSubtitle(item))}})],1)]}}],null,false,3678087455)}):_vm._e(),(_vm.field.type === 'file')?_c('v-file-input',{attrs:{"accept":_vm.field.accept || 'application/pdf',"label":_vm.field.text,"rules":!_vm.readonly ? _vm.rules : []},on:{"change":_vm.updateValue}}):_vm._e(),(_vm.field.type === 'check')?_c('v-checkbox',{attrs:{"disabled":_vm.readonly,"label":_vm.field.text,"true-value":1,"false-value":0,"input-value":_vm.value,"full-width":"","required":""},on:{"change":_vm.updateValue}}):_vm._e(),(_vm.field.type === 'textarea')?_c('v-textarea',{attrs:{"value":_vm.value,"rows":3,"rules":!_vm.readonly ? _vm.rules : [],"label":_vm.field.text,"disabled":_vm.readonly},on:{"input":_vm.updateValue}}):_vm._e(),(_vm.field.type === 'datetime')?_c('v-menu',{ref:"menu_datePicked",attrs:{"disabled":_vm.readonly,"close-on-content-click":false,"rules":_vm.rules,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.value,"rules":!_vm.readonly ? _vm.rules : [],"label":_vm.field.text,"clearable":!_vm.readonly,"disabled":_vm.readonly,"readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,2206799162),model:{value:(_vm.menu_datePicker),callback:function ($$v) {_vm.menu_datePicker=$$v},expression:"menu_datePicker"}},[_c('v-date-picker',{attrs:{"disabled":_vm.readonly,"rules":_vm.rules,"dark":"","no-title":""},on:{"input":_vm.dateChange}})],1):_vm._e(),(_vm.field.type === 'password')?_c('v-text-field',{attrs:{"readonly":_vm.readonly,"value":_vm.value,"rules":!_vm.readonly ? _vm.rules : [],"label":_vm.field.text,"type":"password","autocomplete":"new-password"},on:{"input":_vm.updateValue}}):_vm._e(),(_vm.field.type === 'image')?_c('v-card',[_c('h6',[_vm._v(_vm._s(_vm.field.text))]),(_vm.item.id)?_c('v-img',{staticStyle:{"border":"1px solid black"},attrs:{"height":_vm.field.height || '160',"width":_vm.field.width || '200',"src":_vm.field.src(_vm.item)},on:{"click":function($event){return _vm.field.click(_vm.item)}}}):_vm._e()],1):_vm._e(),(_vm.field.type === 'button' && _vm.item.id)?_c('v-btn',{attrs:{"color":_vm.item[_vm.field.value] ? 'primary' : 'gray'},on:{"click":function($event){return _vm.field.click(_vm.item)}}},[_vm._v(_vm._s(_vm.field.text))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }