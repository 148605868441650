<template>
    <div class="wrapper ecommerce-page" v-vcitem="page">
        
        <el-carousel height="80vh" indicator-position="outside">
            <el-carousel-item v-for="carItem in carousel" :key="'car_'+carItem.id">
                <div class="page-header header-filter">
                    <div class="page-header-image"
                         style="z-index:1;filter: brightness(70%);" v-vcbgimage:image="carItem">
                    </div>
                    <div class="content-center text-center" v-vcitem="carItem">
                        <div class="row">
                            <div class="col-md-8 ml-auto mr-auto">
                                <h1 class="title">{{carItem.title}}</h1>
                                <h4 class="description text-white" v-html="carItem.description"></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>


        <!--div class="page-header page-header-small">
            <parallax class="page-header-image"
                 style="background-image: url('img/bg37.jpg')"  v-vcbgimage:image="page">
            </parallax>
           
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h1  class="title">{{page.title}}</h1>
                    </div>
                </div>
            </div>
        </div-->


        <div class="main">
            <div class="section">
                <div class="container">
                    <h2 class="section-title">{{page.subtitle}}</h2>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-6"
                                     v-for="group in groups"
                                     :key="group.id">
                                      
                                      <a :href="'/#/group/' + group.id" >

                                        <card type="product" plain v-vcitem="group">
                                            <img slot="image" class="img-raised" alt="" height="358" width="350" style="object-fit: cover" :src="group.image" v-vcimage:image="group" />
                                            <h4 class="card-title">{{group.title}}</h4>
                                        </card>

                                    </a>

                                </div>
                                <!--div class="col-lg-4 col-md-6" v-vcitem="{type:'group', parent_id:page.id}"></div-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <div class="container">
                <div class="row">
                    <div class="col-md-2 ml-auto_ mr-auto_ left"  v-for="logo in logos" :key="'logo_'+logo.id" style="text-align:left;width:100%">
                        <img slot="image" class="img-raised" v-vcimage:image="logo" />
                    </div>
                </div>
            </div>

            <!-- section -->
            <!--div v-vcitem="footer">
                    
                <div class="container">
                    <h2 class="section-title">{{footer.title}}</h2>
                </div>

                <div class="container" v-vcdescription:description="footer" v-html="footer.description">
                </div>

                <div class="projects-4">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6 px-0">
                                <card type="background" class="card-fashion" style="height:500px" v-vcbgimage:image="footer">
                                </card>
                            </div>
                            <div class="col-md-6 px-0">
                                <div class="card-container">
                                    <div class="card card-fashion">
                                        <div class="card-title">
                                            <h4>
                                                {{footer.subtitle}}
                                            </h4>
                                        </div>
                                    </div>
                                    <card type="background" class="card-fashion" style="height:500px" v-vcbgimage:image1="footer">
                                    </card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div-->




            <footer class="footer footer-big footer-white">
                <div class="container">
                    <hr />
                    <div class="copyright">
                        Copyright &copy;
                        {{year}} Depalop All Rights Reserved.
                    </div>
                </div>
            </footer>
           
        </div>

       

    </div>
</template>
<script>
  import { Card, Button, InfoSection, FormGroupInput, Checkbox, Collapse, CollapseItem, Slider } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui'
  const { getList } = require('@/api/base_entity')



  export default {
    name: 'ecommerce-page',
    bodyClass: 'ecommerce-page',
    components: {
      Card,
      InfoSection,
      Collapse,
      CollapseItem,
      Slider,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip,
    },
    data() {
      return {
        year: new Date().getFullYear(),
        page: {},
        widgets: false,
        groups: [],
        carousel: [],
        logos: [],
        footer: {}
      }
    },
    mounted() {
        this.$root.$on('vuecommerce-update-item', this.updateItems)
        this.updateItems()
    },
    destroyed() {
        this.$root.$off('vuecommerce-update-item')
    },
    methods: {
        updateItems() {
            getList('item', {'parent.name':'homepage', type:'group'}, {sortBy:'weight', rowsPerPage: -1}).then( data => {
                  this.groups = data.data
            })
            getList('item', {type:'page', name:'homepage'})
                .then( data => {
                    this.page = data.data[0]
                    console.log('page', this.page)
                })
                .then(data => {
                    //load carousel
                    getList('item', {type:'content', parent_id:this.page.id}).then( data => {
                        this.carousel = [];
                        this.logos = [];
                        data.data.forEach( el => {
                            if(el.name == 'carousel') {
                                this.carousel.push(el)
                            }
                            if(el.name == 'footer') {
                                this.footer = {}
                                this.footer = el
                            }
                            if(el.name == 'logo') {
                                this.logos.push(el)
                            }
                        })
                        // this.carousel = data.data.map( el => el.name == 'carousel')
                        // this.footer = data.data.map( el => el.name == 'footer')
                    })
                })
           
        },
    },
  }
</script>

<style>

@import url("//unpkg.com/element-ui@2.15.0/lib/theme-chalk/index.css");

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }


    /* .card-product .card-image {
        width: 350px !important;
        margin: 0 auto;
    } */


</style>
