import router from '@/router'
//import store from './store'
import store from '@/store'

import  abilityObj  from './utils/ability'

import {getInfo} from './api/auth'

import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken, removeToken } from './utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/vuecommerce/login', '/vuecommerce/forgot-pass', '/vuecommerce/confirm-forgot-pass'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {

  // start progress bar
  NProgress.start()

  if (to.path === '/vuecommerce/logout') {
    // await store.dispatch('user/logout')
    removeToken()
    NProgress.done()
    next({ path: '/vuecommerce/login' })
    return
  }

  
  // const ability = store.getters.ability;

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    setToken(hasToken)
    if (to.path === '/vuecommerce/login') {
      // if is logged in, redirect to the home page
      next({ path: '/vuecommerce' })
      NProgress.done()
    } else {

      const canNavigate = to.matched.some(route => {
        return abilityObj.can('read', route.meta.entity)
      });

      if (!canNavigate) {
        NProgress.done()
        return next('/vuecommerce/dashboard')
      }

      const hasGetUserInfo = store.state.vuecommerce.user.name

      if (hasGetUserInfo) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')
          next()
        } catch (error) {
          // remove token and go to login page to re-login
          removeToken()
          Message.error(error || 'Has Error')
          next(`/vuecommerce/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1 || !to.path.startsWith("/vuecommerce")) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/vuecommerce/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
