import request from './request'
import { setToken } from '../utils/auth'
import store from '@/store'
import  abilityObj  from '../utils/ability'

export function login (data) {
  const response = request({
    url: '/auth/login',
    method: 'post',
    data
  })
  response.then( data => {
    setToken(data.token)
    abilityObj.update(data.rules)
  })
  return response
}

export function getInfo () {
  const ret = request({
    url: '/auth/check',
    method: 'get'
  })
  ret.catch(() => console.error('User not logged'))
  ret.then( data => {
    // const ability = store.getters.ability
    abilityObj.update(data.rules)
  })
  return ret
}

export function logout () {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}

export function forgotPass (data) {
  return request({
    url: '/auth/forgotPass',
    method: 'post',
    data
  })
}

export function confirmForgotPass (data) {
  return request({
    url: '/auth/confirmForgotPass',
    method: 'post',
    data
  })
}

export function changePass (data) {
  return request({
    url: '/auth/changePass',
    method: 'post',
    data
  })
}