export default {
  'entityName': 'account',
  'pagination': {
    'rowsPerPage': 10,
    'page': 1,
    'sortBy': 'id',
    'descending': true
  },
  'fields': [
    {
      value: 'email',
      text: 'Email',
      'type': 'text',
      'table': {
        'sortable': true,
        'filter': true
      },
      'validators': [
        'required',
        'email'
      ]
    },
    {
      value: 'name',
      text: 'Name',
      'type': 'text',
      'table': {
        'sortable': true,
        'filter': true
      },
      render: item => `${item.name} ${item.surname}`,
      validators: ['required']
    },
    {
      value: 'surname',
      text: 'Surname',
      'type': 'text',
      'validators': [
        'required'
      ]
    },
    {
      value: 'role',
      text: 'Role',
      'type': 'list',
      'table': {
        'sortable': true,
        filter: true
      },
      'list': (item, state) => {
        let types = [
          'Administrator',
          'Customer',
        ]
        if (state.vuecommerce.user.role === 'Administrator') {
          types.unshift('Administrator')
        }
        return types
      },
      'validators': [
        'required'
      ]
    },
    {
      value: 'status',
      text: 'Status',
      'type': 'list',
      'table': {
        'sortable': true,
        'filter': true
      },
      'list': [
        'Active',
        'Disabled'
      ],
      'validators': [
        'required'
      ]
    }
  ],
  actions: ['upsert']
}
