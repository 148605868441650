<template>
  <v-app>

    <core-drawer/>
    <core-toolbar />

    <v-main>

      <div id="core-view">
        <v-fade-transition mode="out-in">
          <slot/>
        </v-fade-transition>
      </div>
      <core-footer />
    </v-main>

    <core-snackbar/>
    <!-- <core-loader /> -->
    <core-confirm ref="confirm" />

    <app-images-modal :open="openImages" :data="data" @close="openImages=false" @choosedImage="choosedImageEvt"/>
    <app-description-modal :open="openDescription" :data="data" @close="openDescription=false" @changedDescription="changedDescriptionEvt"/>


  </v-app>
</template>
<script>
export default {
  data() {
    return {
      openImages: false,
      openDescription: false,
      data: {},
    }
  },
  mounted() {
    this.$root.$on('vuecommerce-upsert-image', this.upsertImage);
    this.$root.$on('vuecommerce-upsert-description', this.upsertDescription);
    this.$root.$confirm = this.$refs.confirm
  },
  destroyed () {
    this.$root.$off('vuecommerce-upsert-image');
    this.$root.$off('vuecommerce-upsert-description');
  },
  methods: {
    upsertImage(data) {
      this.data = data
      this.openImages = true;
    },
    upsertDescription(data) {
      this.data = data
      this.openDescription = true;
    },
    choosedImageEvt() {
    },
    changedDescriptionEvt() {
    },
  }
}
</script>
<style lang="scss">


.theme--light.v-application {
  background-color: rgb(230, 230, 230);
}
//  @import '@/styles/index.scss';

/* Remove in 1.2 */
// .v-datatable thead th.column.sortable i {
//   vertical-align: unset;
// }
</style>
