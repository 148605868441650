export default {
  'entityName': 'item',
  'fullscreen': false,
  'pagination': {
    'rowsPerPage': 10,
    'page': 1,
    'sortBy': 'weight',
    'descending': false
  },
  'fields': [
    {
      small:true,
      value: 'parent_id',
      text: 'Group',
      reference: 'item',
      'type': 'autocomplete',
      'table': {
        'filter': true
      },
      title: (item) => { return item.name + ' (' + item.type + ')'},
      render: (item) => { return item.parent.name + ' (' + item.parent.type + ')'},
      filter: (item, search) => { return {'type': ['group']} }
    }, 
    {
      mini:true,
      value: 'name',
      text: 'Name',
      'type': 'text',
      'table': {
        'sortable': true,
        'filter': true
      },
      'validators': [
        'required',
      ]
    },       
   
    {
      mini:true,
      value: 'subtitle',
      text: 'Subtitle',
      'type': 'text',
      'table': {
        'sortable': true,
        'filter': true
      },
    },    
    
    {
      mini:true,
      value: 'price',
      text: 'Price',
      'type': 'number',
      'table': {
        'sortable': true,
        'filter': true
      },
      validators: ['required']
    },
    {
      small:true,
      value: 'price_descr',
      text: 'Price description',
      'type': 'text',
    },
    {
      mini:true,
      value: 'discount_percentage',
      text: 'Discount %',
      'type': 'number',
      // validators: ['required']
    },
    {
      mini:true,
      value: 'discount_amount',
      text: 'Discount amount',
      'type': 'number',
      // validators: ['required']
    },


    {
      small:true,
      value: 'status',
      text: 'Status',
      'type': 'list',
      'table': {
        'sortable': true,
        filter: true
      },
      'list': (item, state) => {
        let types = [
          'Enabled',
          'Disabled',
        ]
        return types
      },
      'validators': [
        'required'
      ]
    },


    {
      value: 'title',
      text: 'Title1',
      mini:true,
      'type': 'text',
    },    
    {
      value: 'title2',
      text: 'Title2',
      mini:true,
      'type': 'text',
    },    
    {
      value: 'title3',
      text: 'Title3',
      mini:true,
      'type': 'text',
    },    
    {
      value: 'title4',
      text: 'Title4',
      mini:true,
      'type': 'text',
    },    
    {
      value: 'title5',
      text: 'Title5',
      mini:true,
      'type': 'text',
    },    
    {
      value: 'title6',
      text: 'Title6',
      mini:true,
      'type': 'text',
    },    


    {
      value: 'description',
      text: 'Description 1',
      'type': 'button',
      mini:true,
      click: (item) => { 
        const data = {
            id: item.id,
            field_name: 'description'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-description', data)
      },
    },

    {
      value: 'description1',
      text: 'Description 2',
      'type': 'button',
      mini:true,
      click: (item) => { 
        const data = {
            id: item.id,
            field_name: 'description1'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-description', data)
      },
    },

    {
      value: 'description2',
      text: 'Description 3',
      'type': 'button',
      mini:true,
      click: (item) => { 
        const data = {
            id: item.id,
            field_name: 'description2'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-description', data)
      },
    },

    {
      value: 'description3',
      text: 'Description 4',
      'type': 'button',
      mini:true,
      click: (item) => { 
        const data = {
            id: item.id,
            field_name: 'description3'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-description', data)
      },
    },

    {
      value: 'description4',
      text: 'Description 5',
      'type': 'button',
      mini:true,
      click: (item) => { 
        const data = {
            id: item.id,
            field_name: 'description4'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-description', data)
      },
    },

    {
      value: 'description5',
      text: 'Description 6',
      'type': 'button',
      mini:true,
      click: (item) => { 
        const data = {
            id: item.id,
            field_name: 'description5'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-description', data)
      },
    },

    {
      value: 'image_id',
      text: 'Image 1',
      height:'100',
      width:'160',
      src: (item) => { return process.env.VUE_APP_BASE_API + '/images/' + (item.image ? item.image.file : '') }, 
      click: (item) => { 
        
        const data = {
            id: item.id,
            field_name: 'image_id'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-image', data)
      },
      type: 'image',
      mini:true
    },

    {
      value: 'image1_id',
      text: 'Image 2',
      'type': 'image',
      mini:true,
      height:'100',
      width:'160',
      src: (item) => { return process.env.VUE_APP_BASE_API + '/images/' + (item.image1 ? item.image1.file : '') }, 
      click: (item) => { 
        
        const data = {
            id: item.id,
            field_name: 'image1_id'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-image', data)
      },    },

    {
      value: 'image2_id',
      text: 'Image 3',
      'type': 'image',
      mini:true,
      height:'100',
      width:'160',
      src: (item) => { return process.env.VUE_APP_BASE_API + '/images/' + (item.image2 ? item.image2.file : '') }, 
      click: (item) => { 
        
        const data = {
            id: item.id,
            field_name: 'image2_id'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-image', data)
      },    },

    {
      value: 'image3_id',
      text: 'Image 4',
      'type': 'image',
      mini:true,
      height:'100',
      width:'160',
      src: (item) => { return process.env.VUE_APP_BASE_API + '/images/' + (item.image3 ? item.image3.file : '') }, 
      click: (item) => { 
        
        const data = {
            id: item.id,
            field_name: 'image3_id'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-image', data)
      },    },

    {
      value: 'image4_id',
      text: 'Image 5',
      'type': 'image',
      mini:true,
      height:'100',
      width:'160',
      src: (item) => { return process.env.VUE_APP_BASE_API + '/images/' + (item.image4 ? item.image4.file : '') }, 
      click: (item) => { 
        
        const data = {
            id: item.id,
            field_name: 'image4_id'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-image', data)
      },    },

    {
      value: 'image5_id',
      text: 'Image 6',
      'type': 'image',
      mini:true,
      height:'100',
      width:'160',
      src: (item) => { return process.env.VUE_APP_BASE_API + '/images/' + (item.image5 ? item.image5.file : '') }, 
      click: (item) => { 
        
        const data = {
            id: item.id,
            field_name: 'image5_id'
        }
        window.vueapp.$root.$emit('vuecommerce-upsert-image', data)
      },    
    },
    

    

   
    // {
    //   "value": "site_id",
    //   "text": "Site",
    //   title: "name",
    //   subtitle: item => `${item.city} - ${item.code}`,
    //   "type": "autocomplete",
    //   filter: (item, search) => [{ name: `%${search}%`}, {city: `%${search}%`}, {code: `%${search}%`}],
    //   "table": {
    //       "sortable": true,
    //       filter: true
    //   },
    //   render: item => item.role === 'Site' && `${item.site.city} - ${item.site.name}` || '',
    //   visible: item => item.role === 'Site'
    // },
  ],
  actions: ['upsert', 'remove'],

}
