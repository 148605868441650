import Vue from "vue";
import Router from "vue-router";
// import Components from "./pages/Components.vue";
// import Presentation from "./pages/Presentation.vue";
import MainNavbar from "./layout/MainNavbar.vue";
// import MainFooter from "./layout/MainFooter.vue";
// import About from "./pages/example-pages/AboutUs.vue";
// import BlogPost from "./pages/example-pages/BlogPost.vue";
// import BlogPosts from "./pages/example-pages/BlogPosts.vue";
import ContactUs from "./pages/ContactUs.vue";
import AboutUs from "./pages/AboutUs.vue";
// import LandingPage from "./pages/LandingPage.vue";
// import LoginPage from "./pages/LoginPage.vue";
// import PricingPage from "./pages/example-pages/PricingPage.vue";
import GroupPage from "./pages/GroupPage.vue";
import HomePage from "./pages/HomePage.vue";
import ProductPage from "./pages/ProductPage.vue";
// import ProfilePage from "./pages/example-pages/ProfilePage.vue";
// import SignUpPage from "./pages/example-pages/SignUpPage.vue";
// import Sections from "./pages/Sections.vue";


Vue.use(Router);

const router = new Router({
  routes: [
    // {
    //   path: "/login",
    //   name: "login",
    //   components: {default: LoginPage, header: MainNavbar},
    //   props: {header: {colorOnScroll: 450}}
    // },
    {
      path: "/",
      name: "homepage",
      components: {default: HomePage, header: MainNavbar},
      props: {header: {colorOnScroll: 460}}
    },
    {
      path: "/group",
      name: "groups",
      components: {default: HomePage, header: MainNavbar},
      props: {header: {colorOnScroll: 460}}
    },
    {
      path: "/group/:group_id",
      name: "group",
      components: {default: GroupPage, header: MainNavbar},
      props: {header: {colorOnScroll: 460}}
    },
    {
      path: "/product/:product_id",
      name: "product",
      components: {default: ProductPage, header: MainNavbar},
      props: {header: {colorOnScroll: 315}}
    },
    {
      path: "/contact",
      name: "contact",
      components: {default: ContactUs, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/about_us",
      name: "about_us",
      components: {default: AboutUs, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    // {
    //   path: "/landing",
    //   name: "landing",
    //   components: {default: LandingPage, header: MainNavbar},
    //   props: {header: {colorOnScroll: 450}}
    // },
    // {
    //   path: "/signup",
    //   name: "signup",
    //   components: {default: SignUpPage, header: MainNavbar},
    //   props: {header: {colorOnScroll: 450}}
    // },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router;
