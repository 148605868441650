<template>
  <v-dialog v-model="value" persistent max-width="500px">
    <material-card color="red" :title="title">
      <v-form ref="form">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              Are you sure you want to delete this {{entity}}
              <b>"{{ entityId }}"</b> ? <br /><br />
              ATTENTION!!! This operation is NOT reversible.<br /><br />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <v-flex xs12 text-right>
        <v-btn class="mx-1 font-weight-light" color="danger" @click="confirm()">Delete</v-btn>
        <v-btn
          class="mx-1 font-weight-light"
          color="primary"
          @click="close()">Cancel</v-btn>
      </v-flex>
    </material-card>
  </v-dialog>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    value: Boolean,
    entity: String,
    entityId: String,
    initialsList: Array
  },
  data() {
    return {
    };
  },
  mounted() {},
  watch: {
  },
  computed: {
    title() {
      return 'Delete ' + this.entity + ' (' + this.entityId + ')';
    }
  },
  methods: {
   close() {
      this.$emit("input", false);
    },
    confirm() {
      this.$emit("confirm", true);
      this.$emit("input", false);
    }
  }
};
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
