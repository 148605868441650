<template>
  <v-dialog v-model="value" persistent >
    <material-card color="primary" :title="title">
      <v-form ref="form">
        <v-container grid-list-md>
          <v-layout wrap>
             

                <v-flex xs6 md6>
                  <p v-html="item[field]"></p>
                </v-flex>

                <v-flex xs6 md6>
                  <!--v-textarea
                    autocomplete="false"
                    v-model="translated_value"
                    name="Translated Value"
                    label="Translated Value"
                    id="translated_value"
                    required>
                  </v-textarea-->


                <tiptap-vuetify
                  v-model="translated_value"
                  :extensions="extensions" />


                </v-flex>

             
          </v-layout>
        </v-container>
      </v-form>
      <v-flex xs12 text-right>
       
        <v-btn
          class="mx-1 font-weight-light"
          color="primary"
          @click="close()">Cancel</v-btn>
          
          <v-btn class="mx-1 font-weight-light" color="orange" @click="clear()">Clear</v-btn>

          <v-btn class="mx-1 font-weight-light" color="danger" @click="upsert()">Save</v-btn>

      </v-flex>
    </material-card>
  </v-dialog>
</template>

<script>

import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

const { getDetail, upsert, remove } = require('../../api/base_entity')

export default {
  components: { TiptapVuetify },
  inheritAttrs: false,
  props: {
    value: Boolean,
    translate: Object,
    fieldLang: String,
  },
  data() {
    return {
      item: {},
      translated_value: '',
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  mounted() {},
  watch: {
      value(v) {
        if (v) {
          getDetail('item', {id: this.translate.id}).then( data => {
            this.item = data
          })
          this.translated_value = this.translate[this.fieldLang]
        }
      }
  },
  computed: {
    title() {
      return 'Translate ' + this.field + ' ' + this.lang;
    },
    lang() {
      if (!this.fieldLang) return
      return this.fieldLang.split('_')[1]
    },
    field() {
      if (!this.fieldLang) return
      return this.fieldLang.split('_')[0]
    },

  },
  methods: {
   close() {
      this.$emit("input", false);
    },
    upsert() {
      upsert('translate', 
        {
          item_id: this.item.id, 
          lang:this.lang,
          field:this.field,
          value: this.translated_value
        }
      ).then( data => {
          this.$root.$emit('openSnackbar', {
            message: 'Translate saved successfully!',
            color: 'success'
          })
          this.$emit("saved", data);
      })
    },
    clear() {
      const primaryKey = this.item.id + '_' + this.lang + '_' + this.field
      remove('translate', {id:primaryKey}).then( data => {
        this.$root.$emit('openSnackbar', {
            message: 'Translate removed successfully!',
            color: 'success'
        })
        this.$emit("saved", data);
      })
    },
  }
};
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
