<template>
  <v-list-group
    class='text--lighten-5'
    no-action
    :prepend-icon="icon">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="group-tile-title">{{ text }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="(link, i) in allowedLinks"
      link exact
      :key="i"
      :to="link.to"
      :active-class="color">
      <v-list-item-action>
        <v-icon>{{ link.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-title v-text="link.text" />
    </v-list-item>

  </v-list-group>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['to', 'active_class', 'text', 'icon', 'links'],
  computed: {
    ...mapState('app', ['image', 'color']),
    allowedLinks () {
      // const r = this.links.filter(link => !link.entity || this.$can(link.action, link.entity))    
      const r = this.links.filter(link => link)    
      return r
    }
  }
}
</script>