import { getToken } from '../utils/auth' // get token from cookie

const borderStyle = "1px dotted #52D400";

function buildVcImage(el, binding, vnode, bg = false) {

    el.style.minWidth = "50px"
    el.style.minHeight = "50px"

    const value = binding.value;

    if (!value.id) {
        return
    }

    if (value && value[binding.arg] && value[binding.arg].file) {
        const newSrc = process.env.VUE_APP_BASE_API + '/images/' + value[binding.arg].file
        if (bg) {
            el.test = "url('" + newSrc + "')";
            el.style.backgroundImage = "url('" + newSrc + "')";
        } else {
            el.src = newSrc
        }
    } else {
        if (!getToken()) {
            el.style.display='none';
        }
    }


    if (!binding.arg) {
        console.warn("vcimage directive need column name of entity")
        return
    }
    if (!getToken()) {
        return
    }

    el.style.border=borderStyle;
    el.style.cursor = 'pointer';
   
    // var btn = document.createElement("BUTTON");
    // btn.style=buttonStyle;
    // btn.style.left = elemRect.left + 'px';
    // btn.style.top = elemRect.left + 'px';
    // btn.style.zIndex = '1000000'
    // btn.innerHTML = "Edit Image";
    //el.parentNode.insertBefore(btn, el.nextSibling);


    if(el.classList.contains('vcimage')) {
        return
    }

    // console.log("buildVcImage - " + value.type, value)

    el.classList.add('vcimage')
    el.title = "Click to change " + binding.arg + ' id : ' +  value.id + ', type : ' + value.type
    // el.title = "Click to change " + binding.arg

    el.addEventListener("click", (event) => { // Open Image Modal
        event.preventDefault();
        const data = {
            id: value.id,
            field_name: binding.arg + '_id'
        }
        vnode.context.$root.$emit('vuecommerce-upsert-image', data);
    })


    vnode.context.$root.$on('vuecommerce-update-image', (data) => { // Update image src

        const image = data.image.replace("_id", '');

        if (data.item.id == value.id && binding.arg == image) {
            let newSrc = ''
            if(data.item[image]) {
                newSrc = process.env.VUE_APP_BASE_API + '/images/' + data.item[image].file
            }
            if (bg) {
                el.style.backgroundImage = "url('" + newSrc + "')";
            } else {
                el.src = newSrc
            }
        }
    });

}


export default {

    image: {
        deep: true,
        update: async function (el, binding, vnode) {
            buildVcImage(el, binding, vnode)
        },
        inserted: async function (el, binding, vnode) {
            const value = binding.value instanceof Promise ? await binding.value : binding.value;
            if (!value.id) { // maybe observer, call update hook
                return
            }
            buildVcImage(el, binding, vnode)
        },
        unbind: function (el, binding, vnode) {
            vnode.context.$root.$off('vuecommerce-update-image')
        }
    },
    bgimage: {
        deep: true,
        update: async function (el, binding, vnode) {
            const value = binding.value
            buildVcImage(el, binding, vnode, true)
        },
        inserted: async function (el, binding, vnode) {

            const value = binding.value instanceof Promise ? await binding.value : binding.value;
            if (!value.id) { // maybe observer, call update hook
                return
            }
            buildVcImage(el, binding, vnode, true)

        },
        unbind: function (el, binding, vnode) {
            vnode.context.$root.$off('vuecommerce-update-image')
        }
    }

}