<template>
<v-dialog v-model="loading" fullscreen full-width>
  <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.5);">
    <v-layout justify-center align-center>
      <v-progress-circular
        indeterminate
        color="primary">
      </v-progress-circular>
    </v-layout>
  </v-container>
</v-dialog>
</template>


<script>
export default {
  data: () => ({}),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
  },
  methods: {}
};
</script>



