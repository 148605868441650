import Login from './views/auth/Login.vue'
import Dashboard from './views/Dashboard.vue'
import ForgotPass from './views/auth/ForgotPass.vue'
import CreatePass from './views/auth/CreatePass.vue'
import AccountList from './views/account/Index.vue'
import GroupList from './views/group/Index.vue'
import PageList from './views/page/Index.vue'
import ArticleList from './views/article/Index.vue'
import ContentList from './views/content/Index.vue'
import OrderList from './views/order/Index.vue'
import OrderUpsert from './views/order/Upsert.vue'
import Preview from './views/Preview.vue'
import Translate from './views/translate/Index.vue'

const routes = [
    {
      path: "/vuecommerce",
      name: "Dashboard",
      component: Dashboard,
      meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/login",
        component: Login,
        name: "Login",
        meta: { vuecommerce: true, layout: 'vuecommerce-no-sidebar' }
    },
    {
        path: "/vuecommerce/logout",
        component: Login,
        name: "Logout",
        meta: { vuecommerce: true, layout: 'vuecommerce-no-sidebar' }
    },
    {
        path: "/vuecommerce/forgot-pass",
        component: ForgotPass,
        name: "Forgot Password",
        meta: { vuecommerce: true, layout: 'vuecommerce-no-sidebar' }
    },
    {
        path: "/vuecommerce/create-pass",
        component: CreatePass,
        name: "Create Password",
        meta: { vuecommerce: true, layout: 'vuecommerce-no-sidebar' }
    },
    {
        path: "/vuecommerce/account",
        component: AccountList,
        name: "Accounts List",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/account/:id",
        name: "Account Detail",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/group",
        component: GroupList,
        name: "Groups List",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/page",
        component: PageList,
        name: "Page List",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/article",
        component: ArticleList,
        name: "Articles List",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/article/:id",
        name: "Article Detail",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/content",
        component: ContentList,
        name: "Contents List",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/content/:id",
        name: "Content Detail",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/settings",
        name: "Settings",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/order",
        component: OrderList,
        name: "Orders List",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/order/:id",
        component: OrderUpsert,
        name: "Order Detail",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/translate",
        component: Translate,
        name: "Translate",
        meta: { vuecommerce:true}
    },
    {
        path: "/vuecommerce/Preview",
        component: Preview,
        name: "Preview",
        meta: { vuecommerce:true}
    },
   
]

export default routes