import _ from 'lodash'

export default {
  required (name) {
    return v => !!v || `${name} is required`
  },
  password: v => (v && v.length >= 8) || 'The password must have at least 8 characters',
  confirm_password: (v, password) => {
    return (v && v === password) || 'The password does not match'
  },
  email () {
    return v => /.+@.+/.test(v) || 'E-mail must be valid'
  },
  number (name) {
    return v => isFinite(v) || `${name} has to be a number`
  },
  serials () {
    return serials => {
      const normalized = _(serials)
        .split(/,|\n| /)
        .invokeMap('trim')      // trim
        .filter(c => c)         // tolgo i vuoti
        .value()

      for (let chunk of normalized) {
        if (!chunk.includes('-')) continue
        let [min, max] = chunk.split('-')
        min = Number(min)
        max = Number(max)
        if (min>max) return 'Range should be min-max'
        if (max-min>10000) return 'Range should not contain more than 10k serials'
      }
      return true
    }
  },
  username: [
    v => !!v || 'Username is required',
  ],
  account: {
    email: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    password: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 8) || 'The password must have at least 8 characters'
    ],
    name: [
      v => !!v || 'Name is required'
    ],
    surname: [
      v => !!v || 'Surname is required'
    ],
    role: [
      v => !!v || 'Role is required'
    ],
    status: [
      v => !!v || 'Status is required'
    ]
  }
}
