 <template>
  <v-snackbar
    :color="color"
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    v-model="active"
    dark
  >
    <v-icon color="white" class="mr-3">
      mdi-bell-plus
    </v-icon>
    <div>
      {{ message }}
    </div>
    <v-icon size="16" @click="close()">
      mdi-close-circle
    </v-icon>
  </v-snackbar>
</template>

      

<script>
export default {
  data() {
    return {};
  },
  computed: {
    active: {
        get() {
            return this.$store.getters.snackbar.active || false;
        },
        set() {
            return this.$store.dispatch("app/closeSnackbar");
        }
    },
    message() {
      return this.$store.getters.snackbar.message || 'Hi!';
    },
    color() {
      return this.$store.getters.snackbar.color || 'success';
    },
    top() {
      return this.$store.getters.snackbar.top || true;
    },
    bottom() {
      return this.$store.getters.snackbar.bottom || false;
    },
    left() {
      return this.$store.getters.snackbar.left || false;
    },
    right() {
      return this.$store.getters.snackbar.right || true;
    }
  },
  methods: {
    close() {
      return this.$store.dispatch("app/closeSnackbar");
    }
  }
};
</script>