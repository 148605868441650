import { Ability } from '@casl/ability'

var abilityObj;

if (!abilityObj) {
    abilityObj = new Ability([
        { action: ['read'], subject: ['all'] }
    ])
}

export default abilityObj

// for update abilities
// abilityObj.update({})