<template>
  <material-card
    :title="title || config.title"
    :text="subtitle"
    color="primary"
    class="elevation-1">

    <!-- embedded upsert dialog -->
    <v-dialog
      v-if="!hideUpsert && $can('upsert', entityName) && config.upsert !== 'full' && openUpsert"
      v-model="openUpsert"
      :max-width="upsertMaxWidth">
      <slot name="upsert">
        <app-upsert
          v-if="openUpsert"
          :title="upsertTitle || config.title || title || entityName"
          :item="item || hiddenFields"
          :config="config"
          :subtitle="subtitle"
          :apiUpsert="apiUpsert"
          :apiGetdetail="apiGetdetail"
          modal
          @saved="upsertSaved"
          @close="openUpsert=false" 
          @updateItem="updateItem"/>
      </slot>

    </v-dialog>

    <!-- TODO: should put a slot header here -->
    <template slot='headerActions'>
      <slot name='headerActions'/>
      <v-btn
        v-if="!hideUpsert && !hideCreate && $can('upsert', entityName)"
        color="pink"
        @click="upsertAction()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <!-- embedded inline upsert, should use Upsert  -->
    <v-form
      class='mt-2'
      v-if="inlineUpsert"
      ref="form"
      v-model="validForm">
      <v-container grid-list-md style="max-width:1450px">
        <v-layout wrap>
          <core-field
            v-for="field in visibleFields"
            :readonly="readonly || (typeof field.readonly === 'function' ? field.readonly(item, $store.state) : field.readonly)"
            :key="field.value"
            :field="field"
            :item="item"
            v-model="item[field.value]" />
        </v-layout>
        <v-btn
          :disabled="!validForm"
          class="mx-1 font-weight-light"
          color="success"
          small
          @click="save">Save</v-btn>
      </v-container>
    </v-form>
    <v-layout v-resize="onResize">
    <v-data-table
      style="width: 100%"
      :items="items"
      :headers-length="headers.length"
      :loading="loading"
      :hide-default-footer="totalItems<=pagination.itemsPerPage"
      :options.sync='pagination'
      @update:items-per-page="getData"
      @update:page='getData'
      :server-items-length="totalItems"
      :hide-default-headers="isMobile"
      v-sortable-data-table="sortableDatatable"
      @sorted="sorted"
    >

      <template
        slot="header">
        <thead class='v-data-table-header'>
        <tr>
          <th
            v-for="header in headers"
            :key="header.value"
            :class="[
              'column',
              header.table.align ? 'text-' + header.table.align : 'text-start',
              pagination.descending ? 'desc' : 'asc',
              header.table.sortable !== false && 'sortable',
              header.value === pagination.sortBy && header.table.sortable != false && 'active'
            ]"
            @click="changeSort(header)">

            <span
              class="text--lighten-4"> {{header.text}}
              <v-icon class="v-data-table-header__icon"
                v-if="header.table.sortable !== false && pagination.sortBy === header.value"
                small>mdi-arrow-up
              </v-icon>
            </span>

          </th>
        </tr>
        <tr class="lighten-3" v-if="!noSearch">
          <th
            v-for="header in headers"
            :key="header.value">
            <div v-if="header.table.filter" :style="{ 'max-width': `${ header.table.width || 200}px` }">
              <v-text-field
                class='pt-0 mt-0'
                v-if="header.type === 'text'"
                v-model="localFilters[header.value]"
                :placeholder="header.table.filter.placeholder || 'Search'"
                @input='getData'
                clearable
                md4
              />

              <v-select
                v-if="header.type === 'list'"
                v-model="localFilters[header.value]"
                :items="list(header.list)"
                :placeholder="header.table.filter.placeholder || 'Select'"
                @input='getData'
                clearable
                md4
                class="pt-0 mt-0"
              />
              

              <core-field
                v-if="header.type === 'autocomplete'"
                select
                :field="{
                  value: header.value,
                  text: header.text,
                  id: header.id,
                  filter: header.filter,
                  reference: header.reference,
                  title: header.title,
                  subtitle: header.subtitle,
                  type: 'autocomplete',
                  placeholder: 'Select',
                  full: true,
                }"
                v-model='localFilters[header.value]'
                @input='getData' />

              <v-select
                style="width: 100px;"
                v-if="header.type === 'check'"
                v-model="localFilters[header.value]"
                :items="[{ text: 'True', value: 1 },{ text: 'False', value: 0 }]"
                :placeholder="header.table.filter.placeholder || 'Select'"
                @input="getData"
                clearable
                md4
                class="mt-0 pt-0"
              />

            </div>
          </th>
        </tr>
        </thead>
      </template>

      <template
        slot="item"
        slot-scope="{ item }">
        <tr>
          <td
            v-for="header in headers"
            :key="header.value"
            class="text-left">

            <div style='min-width: 90px;' v-if="header.value === 'actions'" class="text-right">
              <v-tooltip
                v-for="(action, id) in actions"
                :key="id"
                top>
                <template
                  slot="activator"
                  slot-scope="{ on }">
                  <a
                    class="ml-2"
                    flat
                    @click="evt => doAction(action, item, evt)">
                    <v-icon
                      :color="action.color"
                      v-on="on">{{ action.icon }}</v-icon>
                  </a>
                </template>
                <span>{{ action.text }}</span>
              </v-tooltip>
            </div>

            <span v-if="header.render">{{header.render(item) }}</span>
            <span v-else-if="header.type === 'autocomplete'">
             
             
              <div v-if="typeof header.render == 'function'">
                 {{ header.render(item) }}
              </div>

              <div v-if="item[header.value.split('_')[0]]">
                 {{ item[header.value.split('_')[0]][header.title] }}
              </div>
              <div v-else>
                 {{ get(item, header.title) || '' }}
              </div>


            </span>
            <span v-else-if="header.type === 'check'">
              <v-icon v-if="item[header.value] && item[header.value] !== '0'" color='green'>mdi-check</v-icon>
              <v-icon v-else color='red'>mdi-close</v-icon>
            </span>
            <span v-else>{{item[header.value]}}</span>

          </td>

        </tr>
      </template>
    </v-data-table>
    </v-layout>

    <v-flex
      v-if="modal"
      xs12
      text-right>
      <v-btn
        v-if="modal"
        class="mx-1 font-weight-light"
        color="success"
        small
        @click="$emit('close')"
      >Close</v-btn>
    </v-flex>
  </material-card>
</template>
<script>
import { normalizeFilters } from '../../utils/filters'
import { upsert, getList, remove, getDetail } from '../../api/base_entity'
import { get, upperFirst as uf } from 'lodash'
import Sortable from "sortablejs";
/**
 * Generic table component getting data from API,
 * supports search, filter, sort, modal upsert, full page upsert
 */
export default {
  name: 'DataTable',
  props: {
    config: { type: Object, default () {} },
    hideUpsert: { type: [Boolean, Number], default: false },
    hideCreate: { type: [Boolean, Number], default: false },
    hideActions: { type: [Boolean, Number], default: false },
    modal: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    noSearch: { type: Boolean, default: false },
    inlineUpsert: { type: Boolean, default: false },
    hiddenFields: { type: Object, default: () => ({}) },
    filters: { type: Object, default: () => ({}) },
    title: { type: [String, Boolean], default: 'List' },
    upsertTitle: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    apiUpsert: { type: Function, default: upsert },
    apiGetlist: { type: Function, default: getList },
    apiRemove: { type: Function, default: remove },
    apiGetdetail: { type: Function, default: getDetail },
    upsertMaxWidth: { type: String, default: '650px' },
    sortableDatatable: { type: Boolean, default: false },
  },
  data () {
    return {
      entityName: this.config.entityName,
      pagination: this.config.pagination,
      loading: true,
      item: Object.assign({}, this.hiddenFields),
      items: [],
      get,
      totalItems: 0,
      fields: this.config.fields.map(f => {
        if (f.type !== 'hidden') return f
        if (this.hiddenFields[f.value]) {
          f.value = this.hiddenFields[f.value]
        }
        return f
      }),
      autocompleteItems: {},
      actions: this.config.actions && !this.hideActions && this.config.actions.map(action => {
        if (typeof action === 'string') {
          // if (!this.$can(action, this.config.entityName)) return
          return {
            value: action,
            text: action === 'upsert' ? 'Edit' : 'Remove',
            color: 'primary',
            icon: action === 'upsert' ? 'mdi-pencil' : 'mdi-delete'
          }
        }
        if (!this.$can(action.value, this.config.entityName)) return
        return {
          ...action,
          label: action.text || uf(action.value)
        }
      }).filter(a => a),
      localFilters: {},
      openUpsert: false,
      validForm: false,
      isMobile: false,
    }
  },
  computed: {
    headers () {
      const headers = this.fields
        .filter(field => field.table)
      if (this.actions.length && !this.hideActions) {
        headers.push({ text: 'Actions', value: 'actions', table: { sortable: false, align: 'right' }})
      }
      return headers
    },
    visibleFields () {
      return this.fields
        .filter(f => typeof f.visible === 'function' ? 
          f.visible(this.item, this.$store.state) : typeof f.visible === 'undefined' ? true : f.visible)
    }
  },
  async created () {
    this.getData()
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    list (headerList) {
      if (typeof headerList === 'function') {
        return headerList(null, this.$store.state)
      }
      return headerList
    },    
    upsertSaved (item) {
      this.getData()
      this.$emit('saved', item)
    },
    // TOFIX: debounce
    getData () {
      this.loading = true
      // TOFIX localFilters override filters...
      const filters = normalizeFilters(Object.assign(this.localFilters, this.filters), this.fields)
      // la paginazione si chiama itemsPerPage e non piu' rowsPerPage
      this.apiGetlist(this.entityName, filters, {...this.pagination, rowsPerPage: this.pagination.itemsPerPage}).then(data => {
        this.items = data.data
        this.$emit('loaded', this.items)
        this.totalItems = data.tot
        this.loading = false
      })
    },
    changeSort (header) {
      if (header.table.sortable === false) return
      this.pagination.page = 1
      if (this.pagination.sortBy === header.value) {
        if (this.pagination.descending === true) {
          this.pagination.sortBy = undefined
        } else {
          this.pagination.descending = !this.pagination.descending
        }
      } else {
        this.pagination.sortBy = header.value
        this.pagination.descending = false
      }
      this.getData()
    },
    save () {
      this.apiUpsert(this.entityName, this.item)
        .then(data => {
          this.item = Object.assign({}, this.hiddenFields)
          this.$refs.form.reset()
          this.$emit('saved', data)
          this.getData()
          this.$root.$emit('openSnackbar', {
            message: (this.config.title || this.title || uf(this.entityName)) + ' updated correctly',
            color: 'success'
          })
        })
        .catch(e => {
          this.$root.$emit('openSnackbar', {
            message: 'An Error occurred during the ' + (this.config.title || this.title || uf(this.entityName)) + ' update. ' +
              e && e.response ? e.response.data.message : 'Error',
            color: 'error'
          })
        })
    },
    // upsert or remove
    doAction (action, item, evt = null) {
      if (evt) { evt.stopPropagation() }
      switch (action.value) {
        case 'upsert':
          this.upsertAction(item)
          this.$nextTick(() => {
            // TOFIX
            this.$root.$emit('itemUpdated')
          })
          break
        case 'remove':
          this.removeAction(item)
          break
        default:
          this.$emit(action.value, item)
      }
    },
    async removeAction (item) {
      const ret = await this.$root.$confirm.open('Confirm removal', 'Are you sure to remove this item?', { color: 'red' })
      if (ret) {
        if (this.config.id && this.config.id.length>0) {
          item.id = this.config.id.reduce( (ret, id) =>  ret ? ret += `_${item[id]}` : item[id],'')
        }
        this.apiRemove(this.entityName, item)
          .then( () => {
            this.$emit('removed', item)
            this.$root.$emit('openSnackbar', {
              message: (this.config.title || this.title || uf(this.entityName)) + ' removed',
              color: 'success'
            })
            this.getData()
          })
          .catch(e => {
            this.$root.$emit('openSnackbar', {
              message: 'An error occurred during ' + (this.config.title || this.title || uf(this.entityName)) + ' removal: ' +
                e && e.response ? e.response.data.message : 'Error',
              color: 'error'
            })
          })
      }
    },
    upsertAction (item = false) {
      if (!this.config.upsert || this.config.upsert === 'modal') {
        this.item = item && Object.assign({}, item) || { ...this.hiddenFields }
        this.openUpsert = true
      } else {
        if (item) {
          this.$router.push(`/${this.entityName}/${item.id}`)
        } else {
          this.$router.push(`/${this.entityName}/new`)
        }
      }
    },
    updateItem(data) {
      this.item = data
      this.getData()
    },
    sorted(event) {
      this.$emit("sorted", event);
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        if (!binding.value) {
          return;
        }

        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
}
</script>

<style lang="scss">
#dataTable {
  .v-datatable__actions {
    color: black;
    .v-btn__content {
      color: black;
    }
    .v-btn {
      background-color: rgb(241, 241, 241);
    }
  }

  tr.lighten-3 th {
    padding: 0px 5px 0px 5px;
  }
  tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
  }
}
</style>
