import { getToken } from '../utils/auth' // get token from cookie

const buttonStyle = "font-weight:bold;color:#52D400;font-weight:bold:z-index:100000;background-color:white;border:2px solid #52D400;padding:3px;bottom:0px;";

const borderStyle = "1px dotted #52D400";


function buildVcDescription(el, binding, vnode, bg = false) {

    el.style.minWidth = "50px"
    el.style.minHeight = "50px"

    const value = binding.value;

    if(el.classList.contains('vcdescription')) {
        return
    }

    if (!getToken() && value && value[binding.arg] ) {
        el.style.display='block';
        return
    }

    if (!getToken() || (!value.id && !value.type)) {
        el.style.display='none';
        return
    }


    el.style.display='block';
    el.style.border = borderStyle;
    el.style.cursor = 'pointer';
    // var btn = document.createElement("BUTTON");
    // btn.id = 'btn_' + binding.value.type + '_' + binding.arg + '_' + binding.value.id;
    // if(document.getElementById(btn.id)) {
    //     return
    // }
    // btn.style = buttonStyle;
    // btn.innerHTML = (binding.value.id ? 'Edit ' : 'Create ') + ' Description';
    el.addEventListener("click", (event) => {
        event.preventDefault();
        const data = {
            id: value.id,
            field_name: binding.arg
        }
        vnode.context.$root.$emit('vuecommerce-upsert-description', data);
    })
    // el.append(btn);
    el.classList.add('vcdescription')
    el.title = "Click to change " + binding.arg + ' of ' + value.type + ' id : ' +  value.id


}

export default {
    deep: true,
    update: async function (el, binding, vnode) {
        buildVcDescription(el, binding, vnode)
    },
    inserted: async function (el, binding, vnode) {
        const value = binding.value instanceof Promise ? await binding.value : binding.value;
        if (!value.id) { // maybe observer, call update hook
            return
        }
        buildVcDescription(el, binding, vnode)
    },
    unbind: function (el, binding, vnode) {
        vnode.context.$root.$off('vuecommerce-update-description')
    }
}