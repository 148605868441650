import { login, logout, getInfo } from '../../api/auth'
import { getToken, setToken, removeToken } from '../../utils/auth'

const state = {
  token: getToken(),
  account_id: null,
  name: '',
  surname: '',
  email: '',
  site_id: null,
  role: '',
  rules: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ACCOUNT_ID: (state, accountId) => {
    state.account_id = accountId
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_SURNAME: (state, surname) => {
    state.surname = surname
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_ROLE: (state, role) => {
    state.role = role
  },
  SET_RULES: (state, rules) => {
    state.rules = rules
  },
  SET_SITE_ID: (state, site_id) => {
    state.site_id = site_id
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { username, password } = userInfo

    return new Promise((resolve, reject) => {
      login({ username, password }).then(
        response => {

          commit('SET_TOKEN', response.token)
          commit('SET_ACCOUNT_ID', response.account_id)

          commit('SET_NAME', response.account.name)
          commit('SET_SURNAME', response.account.surname)
          commit('SET_EMAIL', response.account.email)
          commit('SET_ROLE', response.account.role)
          commit('SET_RULES', response.rules)

          setToken(response.token)
          resolve(response)
        }
      ).catch(error => {
        reject(error)
      })
    })
  },

  // set token manually
  // setToken ({commit}, token) {
  //   commit('SET_TOKEN', token)
  //   setToken(token)
  //   return new Promise((resolve, reject) => {
  //     getInfo().then(data => {
  //       if (!data) {
  //         reject(Error('Verification failed, please Login again.'))
  //       }

  //       commit('SET_NAME', data.account.name)
  //       commit('SET_SURNAME', data.account.surname)
  //       commit('SET_EMAIL', data.account.email)
  //       commit('SET_ROLE', data.account.role)
  //       commit('SET_RULES', data.rules)

  //       resolve(data)
  //     }).catch(error => {
  //       reject('REJECT ' + error)
  //     })
  //   })    
  // },
  // get user info
  getInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(data => {
        if (!data) {
          reject(Error('Verification failed, please Login again.'))
        }

        commit('SET_ACCOUNT_ID', data.account_id)
        commit('SET_NAME', data.account.name)
        commit('SET_SURNAME', data.account.surname)
        commit('SET_EMAIL', data.account.email)
        commit('SET_ROLE', data.account.role)
        commit('SET_RULES', data.rules)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  // forgotPass ({ commit, state }, { email }) {
  //   return new Promise((resolve, reject) => {
  //     forgotPass({ email }).then(data => {
  //       if (!data) {
  //         reject(Error('Verification failed'))
  //       }

  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  logout ({ commit, state }) {
    return new Promise((resolve) => {
      logout(state.token).finally(() => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        commit('SET_SURNAME', '')
        commit('SET_EMAIL', '')
        commit('SET_ROLE', '')
        removeToken()
        resolve()
      })
      // resetRouter()
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_SURNAME', '')
      commit('SET_EMAIL', '')
      commit('SET_ROLE', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
