<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="900px">
    <material-card
      :title="title"
      color="primary">
      <v-form
        ref="form"
        v-model="validForm"
        autocomplete="off"
        role="presentation">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>

                <v-data-table
                  :items="images"
                  :footer-props="{
                    'items-per-page-options': [2,4]
                  }"
                  :items-per-page="4"
                  :headers="[
                    { text: 'Preview', value: 'preview', sortable: false },
                    { text: 'ID', value: 'id', sortable: false },
                    { text: 'Size', value: 'size', sortable: false },
                    { text: 'Width/Height', value: 'dimension', sortable: false },
                    { text: 'Action', value:'action', sortable: false }]"
                >

                <template v-slot:item.preview="{item}">
                    <v-img height="80" width="100" :eager="true" @click="setImage(item)" :src="fullfile(item.file)"/>
                </template>

                <template v-slot:item.size="{item}">
                    {{parseInt(item.size / 1024)}} Kb
                </template>

                 <template v-slot:item.dimension="{item}">
                    {{item.width}} x {{item.height}}
                </template>

                 <template v-slot:item.action="{item}">
                    
                    <!--a flat @click="edit(item)">
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a flat @click="remove(item)">
                      <v-icon color="primary">mdi-delete</v-icon>
                    </a-->


                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                       <v-icon  v-bind="attrs" v-on="on" color="primary">mdi-adjust</v-icon>
                    </template>
                    <v-list>
                      <v-list-item link v-if="item.file.split('.').pop() == 'png'">
                        <v-list-item-title @click="adjust(item, 'background_remove')">Transparent BG</v-list-item-title>
                      </v-list-item>
                      <v-list-item link>
                        <v-list-item-title @click="adjust(item, 'thumb')">Thumbnail</v-list-item-title>
                      </v-list-item>
                       <v-list-item link>
                        <v-list-item-title @click="adjust(item, 'crop')">Crop</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a flat @click="remove(item)">
                      <v-icon color="primary">mdi-delete</v-icon>
                    </a>

                </template>
              
              </v-data-table>

            </v-flex>
          </v-layout>
        </v-container>
      </v-form>

      <v-flex
        xs12
        text-right>
        <v-btn
          class="mx-1 font-weight-light"
          color="success"
          @click="close()"
        >Close</v-btn>


        <v-btn
          v-if="$can('upsert', entityName)"
          class="mx-1 white--text"
          color="orange"
          @click="clear()"> Clear</v-btn>


        <v-btn
          v-if="$can('upsert', entityName)"
          class="mx-1 white--text"
          color="purple"
          @click="pickFile()"> Upload</v-btn>

      </v-flex>
    </material-card>
    
     <input
        id="file"
        ref="file"
        type="file"
        style="display: none"
        accept=".jpg, .png, .jpeg, application/image"
        @change="handleFileUpload()"
      />

  </v-dialog>
</template>

<script>

import { upperFirst as uf } from 'lodash'

const { upsert, getList, uploadImage, remove } = require('../../api/base_entity')

export default {
  props: {
    open: { type: Boolean, default: false },
    data: { type: Object, default: {} }
  },
  data () {
    return {
      validForm: false,
      entityName: 'image',
      images: []
    }
  },
  computed: {
    isOpen () {
      return this.open
    },
    title () {
      return "Update Images"
    },
  },
  watch: {
    open (v) {
        this.getData()
    }
  },
  methods: {
    // openModal (data) {
    //   this.data = data
    //   this.open = true
    //   return new Promise((resolve, reject) => {
    //     this.resolve = resolve
    //     this.reject = reject
    //   })
    // },
    getData () {
      getList('image', { item_id: this.data.id }, {sortBy:'id',descending:true}).then( data => {
        this.images = data.data
      })
    },
    setImage(image) {
      const data = {
        id: this.data.id,
        [this.data.field_name] : image.id
      }
      upsert('item', data).then( ret => {
        const data = {
          image: this.data.field_name,
          item: ret.data
        }
        this.$root.$emit('vuecommerce-update-image', data)
        this.$root.$emit('refreshLoaedItem', data)
        this.$emit('choosedImage', data)
        this.close()
      })
    },
    adjust(item, type) {
      const data = {
        id: item.id,
        adjust_type: type
      }
      upsert('adjust_image', data).then( data => {
        this.getData()
        if(data.status == 'queued') {
          const url = process.env.VUE_APP_BASE_API + '/pub/img_exists/' + data.expectedFile
          this.$polling.addObserver(url,{maxFails: 30})
          this.$options.listeners[url] = (resp) => {
            console.log(resp)
            if (resp.data.exists) {
             this.images = []
             this.getData()
             this.$forceUpdate();
             delete this.$options.listeners[url]
             this.$polling.removeObserver(url)
            }
          }
        }
      })
    },
    clear() {
      this.setImage({id:null})
    },
    async remove(image) {
      console.log(image)
        const ret = await this.$root.$confirm.open('Confirm removal', 'Are you sure to remove this image?', { color: 'red' })
        if (ret) {
          //  upsert('item', data).then( ret => {})
          remove('image',image).then( ret => {
            this.getData()
          })
      }
    },
    close () {
      this.$emit('close')
    },
    pickFile() {
      this.$refs.file.click();
    },
    handleFileUpload(event) {

      const file = this.$refs.file.files[0];
      if (!file) {
        return;
      }
      this.$store.dispatch("app/startLoading");
      let formData = new FormData();
      formData.append('file', file);
      formData.append('id', this.data.id);

      uploadImage(formData).then( data => {
        this.getData()
      })

      this.$refs.file.value = '';
    },
    fullfile(file) {
      return process.env.VUE_APP_BASE_API + '/images/' + file
    }
  }
}
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
