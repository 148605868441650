
import { set } from '@/utils/vuex'

const state = {
  loading: false,
  drawer: true,
}

const mutations = {

  loading: (state, v) => {
    state.loading = v
  },

  setDrawer: (state, v) => {
    state.drawer = v
  },

}

const actions = {
  startLoading: (context) => {
    context.commit('loading', true)
  },
  stopLoading: (context) => {
    context.commit('loading', false)
  },
}


export default {
  // getters
  namespaced: true,
  state,
  mutations,
  actions
}
