<template>
  <v-app-bar 
    app>
    <!-- <v-app-bar-nav-icon @click.stop="onClickBtn"/> -->
    <v-toolbar-title>
      <v-btn
        class="default v-btn--simple"
        dark
        icon
        @click.stop="onClickBtn"
      >
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
      {{ $route.name }}
    </v-toolbar-title>

    <change-password-modal v-model='changePassword'/>
    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2>

        <v-menu
          bottom
          left
          content-class="dropdown-menu"
          offset-y
          transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              text
              color="black"
              v-on="on">
              {{ username }} &nbsp; <v-icon color="tertiary">mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click='changePassword = true'>Change password</v-list-item>
            <v-list-item to="/vuecommerce/logout">
              Logout
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ChangePasswordModal from '../app/ChangePasswordModal'

export default {
  data: () => ({
    title: null,
    responsive: false,
    responsiveInput: false,
    on: null,
    changePassword: false
  }),
  components: { ChangePasswordModal },
  computed: {
    // ...mapState(['vuecommerce']),
    username () {
      const user = this.$store.state.vuecommerce.user
      return `${user.name} ${user.surname } (${user.role})`
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.vuecommerce.app.drawer)
    },
    onClick () {
      //
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    }
  }
}
</script>

<style>
/* #core-toolbar a {
  text-decoration: none;
}
.v-toolbar {
  margin-bottom:0px !important;
} */
</style>
