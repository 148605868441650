<template>

  <v-flex
    v-show='field.type!=="hidden"'
    xs12
    sm6
    :md1="field.micro"
    :md2="field.mini"
    :md3="field.slim"
    :md4="field.small"
    :md12="field.full"
    :md6="!field.small && !field.full"
    :class="select && 'pa-0 ma-0'"

  >

    <v-text-field
      v-if="field.type === 'text' || field.type === 'number' || !field.type"
      :value="value"
      :rules="!readonly ? rules : []"
      :label="field.text"
      :readonly="readonly"
      :disabled="readonly"
      @input="updateValue"
    />

    <v-select
      v-if="field.type === 'list'"
      :disabled="readonly"
      :value="value"
      :items="computedList()"
      :rules="!readonly ? rules : []"
      :label="field.text"
      clearable
      md4
      @input="updateValue"
    />

      <!-- :search-input.sync="searchString" -->
    <v-autocomplete
      v-if="field.type === 'autocomplete'"
      :disabled="readonly"
      :value="value"
      :items="items"
      :rules="!readonly ? rules : []"
      :multiple="field.multiple"
      :chips="field.multiple"
      :small-chips="field.multiple"
      deletable-chips
      no-filter
      :loading="loading"
      :label="!this.select ? field.text : ''"
      :search-input.sync='search'
      :placeholder="field.placeholder || `Choose a ${field.text}...`"
      :class="this.select ? 'ma-0 pa-0' : ''"
      color="green"
      hide-no-data
      :item-text="field.title || 'name'"
      :item-value="field.id || 'id'"
      :prepend-icon="!this.select ? 'mdi-database-search' : ''"
      md4
      clearable
      @change="updateValue">
      <template v-slot:item="{ item }">
        <v-list-item-content two-line>
          <v-list-item-title v-text="autocompleteTitle(item)"/>
          <v-list-item-subtitle v-text="autocompleteSubtitle(item)"/>
        </v-list-item-content>
      </template>
    </v-autocomplete>

      <!-- :search-input.sync="searchString" -->
    <v-combobox
      v-if="field.type === 'combobox'"
      :disabled="readonly"
      :value="value"
      :items="items"
      :rules="!readonly ? rules : []"
      :loading="loading"
      :label="field.text"
      :placeholder="`Choose a ${field.text}...`"
      color="green"
      hide-no-data
      :item-text="field.title || 'name'"
      :item-value="field.id || 'id'"
      prepend-icon="mdi-database-search"
      md4
      clearable
      @change="updateValue">
      <template v-slot:item="{ item }">
        <v-list-item-content two-line>
          <v-list-item-title v-text="autocompleteTitle(item)"/>
          <v-list-item-subtitle v-text="autocompleteSubtitle(item)"/>
        </v-list-item-content>
      </template>
    </v-combobox>


    <v-file-input
      v-if="field.type === 'file'"
      @change='updateValue'
      :accept="field.accept || 'application/pdf'"
      :label="field.text"
      :rules='!readonly ? rules : []'
    />

    <v-checkbox
      v-if="field.type === 'check'"
      :disabled="readonly"
      :label="field.text"
      :true-value="1"
      :false-value="0"
      :input-value='value'
      full-width
      required
      @change="updateValue"
    />

    <v-textarea
      v-if="field.type === 'textarea'"
      :value="value"
      :rows="3"
      :rules="!readonly ? rules : []"
      :label="field.text"
      :disabled="readonly"
      @input="updateValue"
    />

    <v-menu
      v-if="field.type === 'datetime'"
      ref="menu_datePicked"
      v-model="menu_datePicker"
      :disabled="readonly"
      :close-on-content-click="false"
      :rules="rules"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="value"
          :rules="!readonly ? rules : []"
          :label="field.text"
          v-bind="attrs"
          :clearable='!readonly'
          :disabled='readonly'
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        :disabled="readonly"
        :rules="rules"
        dark
        no-title
        @input="dateChange"
      />
    </v-menu>

    <!-- <template slot="item" slot-scope="{ item }" >
      <v-list-tile-content>
          <v-list-tile-title>{{item.name}}</v-list-tile-title>
        </v-list-tile-content>
    </template> -->
    <!-- </v-autocomplete> -->

    <v-text-field
      v-if="field.type === 'password'"
      :readonly="readonly"
      :value="value"
      :rules="!readonly ? rules : []"
      :label="field.text"
      type="password"
      autocomplete="new-password"
      @input="updateValue"
    />


    <v-card  v-if="field.type === 'image'">
        <h6>{{field.text}}</h6>
        <v-img v-if="item.id" :height="field.height || '160'" :width="field.width || '200'" style="border: 1px solid black" @click="field.click(item)" :src="field.src(item)"/>
    </v-card>

        
    <v-btn  v-if="field.type === 'button' && item.id" :color="item[field.value] ? 'primary' : 'gray'" @click="field.click(item)">{{field.text}}</v-btn>



  </v-flex>
</template>
<script>
/**
 * Autocomplete config example:
 * 
 */
import validators from '@/utils/validators'
import get from 'lodash/get'
import uniqBy from 'lodash/unionBy'
const { getList } = require('../../api/base_entity')

export default {
  props: {
    field: { type: Object, default: () => ({}) },
    readonly: { type: Boolean, default: false },
    select: { type: Boolean, default: false },
    value: { type: [String, Number, Boolean, File, Date, Array], default: undefined },
    item: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      cachedItems: [],
      items: [],
      search: '',
      loading: false,
      menu_datePicker: false
    }
  },
  watch: {
    search (val, oldval) {
      if (oldval) {
        this.updateAutocomplete()
      }
    }
  },
  computed: {
    autocomplete_entity () {
      return this.field.reference || this.field.value.split('_')[0]
    },
    rules () {
      if (!this.field.validators) return []
      return this.field.validators.map(v => {
        if (typeof v === 'function') {
          return () => v(this.field, this.value, this.item)
        }
        return typeof validators[v] === 'function' ? validators[v](this.field.text) : validators[v]
      })
    }
  },
  async created () {
    if (this.field.type === 'autocomplete' || this.field.type === 'combobox') {

      // cache values
      if (this.value) {
        if (this.field.multiple) {
          if (this.value.length) {
            this.loading = true
            const id = this.field.id || this.field.value.split('_')[1]
            this.cachedItems = await getList(this.autocomplete_entity, { [id]: this.value }).then(ret => ret.data)
          }
        } else {
            const id = this.field.id || this.field.value.split('_')[1]
            this.cachedItems = await getList(this.autocomplete_entity, { [id]: this.value }).then(ret => ret.data)
        }
      }
      this.updateAutocomplete()
      if ((this.field.filter || this.field.list) && this.field.deps) {
        this.$root.$on('itemUpdated', this.updateAutocomplete)
      }
    }

    if (typeof this.field.render === 'function' && this.readonly) {
      this.$emit('input', this.field.render(this.item))
    }

    if (typeof this.field.default === 'function') {
      this.$root.$on('itemUpdated', this.updateDefault)
      if (typeof this.value === 'undefined' || this.value === null) {
        this.updateDefault({ field: { value: this.field.deps ? this.field.deps[0] : null } })
      }
    }
    //  should check if already evaluated
    //  else if (this.field.default) {
    //   this.$emit('value', this.field.default)
    // }    
  },
  destroyed () {
    this.$root.$off('itemUpdated')
  },
  methods: {
    computedList () {
      if (typeof this.field.list === 'function') {
        return this.field.list(this.item, this.$store.state)
      }
      return this.field.list
    },    
    dateChange (v) {
      this.$emit('input', v)
      this.menu_datePicker = false
    },
    autocompleteSubtitle (item) {
      if (!this.field.subtitle) return
      if (typeof this.field.subtitle === 'string') {
        return get(item, this.field.subtitle)
      }
      return this.field.subtitle(item)
    },
    autocompleteTitle (item) {
      if (!this.field.title) return
      if (typeof this.field.title === 'string') {
        return get(item, this.field.title)
      }
      return this.field.title(item)
    },    
    async updateAutocomplete (args) { // (field = null) {
      if (args !== undefined &&
        (!this.field.deps || !this.field.deps.includes(args.field.value))) {
          return
        }
      this.loading = true
      if (typeof this.field.list === 'function') {
        this.items = await this.field.list(this.autocomplete_entity, this.search, this.item).then(ret => ret.data)
      } else {
        const filters = this.field.filter ? this.field.filter(this.item, this.search) : {}
        if (this.search && !filters[this.field.title] && typeof this.field.title !== 'function') {
          filters[this.field.title] = '%' + this.search + "%"
        }
        let items = await getList(this.autocomplete_entity, filters).then(ret => ret.data)
        if (this.cachedItems) {
          items = items.concat(this.cachedItems)
        }
        this.items = uniqBy(items, this.field.id || this.field.value.split('_')[1])
      }
      this.loading = false
    },
    updateDefault (args) {
      if (args === undefined || !this.field.deps || !this.field.deps.includes(args.field.value)) {
        return
      }
      const value = this.field.default(this.item, args && args.field, args.selectedItem, this.$store.state)
      this.$emit('input', value)
      this.$root.$emit('itemUpdated', { field: this.field, selectedItem:  args && args.selectedItem })

    },
    updateValue (value) {
      if (this.field.type === 'number') { value = Number(value) }

      if (this.field.type === 'combobox' && typeof value === 'object') {
        value = value === null ? null : value[this.field.id || 'id']
      }
      this.$emit('input', value)
      let selectedItem
      if (this.field.type === 'autocomplete' || this.field.type === 'combobox') {
        selectedItem = this.items.find(item => item[this.field.id || 'id'] === value)
      }
      this.$nextTick( () => {
        this.$root.$emit('itemUpdated', { field: this.field, selectedItem })
        this.$emit('input:field', { field: this.field, selectedItem })
      })
    }
  }
}
</script>
