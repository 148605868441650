import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import "./registerServiceWorker"

//plugins
import AsyncComputed from 'vue-async-computed'
import NowUIKit from './plugins/now-ui-kit'
import vuetify from './plugins/vuetify'
import store from '@/store'
import "./plugins/vuecommerce"
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-JY2NG5PNG9" }
}, router);

Vue.use(NowUIKit)
Vue.use(AsyncComputed)

window.vueapp = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
