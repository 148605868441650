<template>
  <material-card
    :title="renderTitle"
    :text="subtitle"
    color="primary">
    <template slot='headerActions'>
      <slot name='headerActions'/>
    </template>
    <v-form
      class="mt-4"
      ref="form"
      lazy-validation
      v-model="validForm"
      autocomplete="off"
      role="presentation">
      <v-container grid-list-md>
        <v-layout wrap>
          <core-field
            v-for="field in visibleFields"
            :readonly="readonly || (typeof field.readonly === 'function' ? field.readonly(item, $store.state) : field.readonly)"
            :key="field.value"
            :field="field"
            :item="item"
            v-model="item[field.value]" />
        </v-layout>
      </v-container>
    </v-form>

    <v-flex
      xs12
      text-right>
      <v-btn
        v-if="modal"
        class="mx-1 font-weight-light"
        color="success"
        @click="close()"
      >Close</v-btn>

      <v-btn
        v-if="$can('upsert', entityName) && !readonly && !hideSave"
        :disabled="!checkSave() && !validForm || loading"
        class="mx-1 white--text"
        color="purple"
        :loading='loading'
        @click="save()"> {{ isNew ? 'Save' : 'Update' }} </v-btn>

      <slot name="actions" :item='item'/>

    </v-flex>
  </material-card>
</template>

<script>

import validators from '../../utils/validators'
import { upperFirst as uf } from 'lodash'

const { upsert, getDetail } = require('../../api/base_entity')

export default {
  props: {
    config: { type: Object, default () {} },
    modal: { type: Boolean, default: false },
    subtitle: { type: String, default: '' },
    title: { type: [String, Boolean], default: '' },
    readonly: { type: Boolean, default: false },
    hideSave: { type: Boolean, default: false },
    item: { type: Object, default: () => ({}) },
    apiUpsert: { type: Function, default: upsert },
    apiGetdetail: { type: Function, default: getDetail },
  },
  mounted() {
    this.$root.$on('refreshLoaedItem', async () => {
      const updated = await getDetail(this.config.entityName, {id:this.item.id})
      this.$emit('updateItem', updated)
    } )
  },
  destroyed() {
    this.$root.$off('refreshLoaedItem')
  },
  data () {
    return {
      loading: false,
      validForm: false,
      validators,
      entityName: this.config.entityName,
      fields: this.config.fields
    }
  },
  computed: {
    isNew () { return !this.item[this.config.id || 'id'] },
    renderTitle () {
      if (this.config.title === false || this.title === false) return ''
      if (this.title) return this.title
      const title = this.config.title || uf(this.entityName)
      return this.isNew ? 'New ' + title : (this.readonly ? title + ' Details' : 'Edit ' + title)
    },
    visibleFields () {
      return this.fields
        .filter(f => typeof f.visible === 'function' ? 
          f.visible(this.item, this.$store.state) : typeof f.visible === 'undefined' ? true : f.visible)
    }
  },
  watch: {
    validForm (value) {
      this.$emit('update:valid', value)
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    checkSave () {
      return !this.isNew
    },
    close () {
      this.$emit('close')
    },
    save () {
      if (!this.$refs.form.validate()) return
      this.loading = true
      this.apiUpsert(this.entityName, this.item)
        .then(data => {
          this.$emit('saved', { data: data.data || data, item: this.item })
          this.$root.$emit('openSnackbar', {
            message: (this.title || this.config.title || uf(this.entityName)) + ' updated correctly',
            color: 'success'
          })
          // this.$store.dispatch('app/close' + uf(this.entityName) + 'Dialog')
          this.close()
        })
        .catch(e => {
          const msg = e && e.response && e.response.data.error_details && Object.values(e.response.data.error_details)[0] || e.response.data.message
          this.$root.$emit('openSnackbar', {
            message: 'An Error occurred during the ' + uf(this.entityName) + ' update. ' +
              msg || 'Error',
            color: 'error'
          })
        })
        .finally (() => {
          this.loading = false
        })
    }
  }
}
</script>
