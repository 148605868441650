
export function normalizeFilters (filters) {
  let newFilters = {}
  Object.keys(filters).forEach(prop => {
    if (typeof filters[prop] === 'string') {
      if (!['<', '>', '!'].includes(filters[prop][0]))
        newFilters[prop] = filters[prop] === '' || filters[prop] === null ? undefined : '%' + filters[prop] + '%'
      else
        newFilters[prop] = filters[prop] === '' || filters[prop] === null ? undefined : filters[prop]
    } else if (typeof filters[prop] !== 'object') {
      newFilters[prop] = filters[prop] === '' || filters[prop] === null ? undefined : filters[prop]
    }
  })
  return newFilters
}
