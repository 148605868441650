import request from '@/utils/request'
import hash from 'object-hash'
import Cookies from 'js-cookie'

const TokenKey = 'vuecommerce_token'
// const in30Minutes = 1 / 48

function getToken() {
  return Cookies.get(TokenKey)
}


 const cache = {}

export function getList (entity, filters = {}, pagination = {}) {
  
  const headers = {
    'x-filters': JSON.stringify(filters),
    'x-pagination': JSON.stringify(pagination),
    'x-lang': Cookies.get('lang')
  }

  let cache_key = '/pub/' + entity + hash(headers)

  if (cache[cache_key] && !getToken()) {
    return Promise.resolve(cache[cache_key])
  }

  const promise = request({
    url: '/pub/' + entity,
    method: 'get',
    headers
  })

  promise.then( ret => {
    cache[cache_key] = ret
  })

  return promise
}


export function getDetail (entity, item) {

  let cache_key = '/pub/' + entity + Cookies.get('lang') + (item && item.id ? '/' + item.id  :  '/' + item)

  if (cache[cache_key] && !getToken()) {
    return Promise.resolve(cache[cache_key])
  }

  const headers = {
    'x-lang': Cookies.get('lang')
  }

  const promise = request({
    url: '/pub/' + entity + (item && item.id ? '/' + item.id  :  '/' + item),
    method: 'get',
    headers
  })

  promise.then( ret => {
    cache[cache_key] = ret
  })

  return promise
}

export function contact(data) {

  const promise = request({
    url: '/pub/contact',
    method: 'post',
    data
  })

  return promise
}

// export function put (entity, id, data) {
//   const method = 'put'
//   const url = '/' + entity + '/' + id
//   return request({
//     url,
//     method,
//     data
//   })
// }

// export function upsert (entity, data) {
//   let method = 'post'
//   let url = '/' + entity
//   if (data.id) {
//     method = 'put'
//     url = '/' + entity + '/' + data.id
//   }
//   return request({
//     url,
//     method,
//     data
//   })
// }

// export function remove (entity, item) {
//   return request({
//     url: '/' + entity + '/' + item.id,
//     method: 'delete'
//   })
// }
