<template>
    <div>

      <vuecommerce-global-item v-if="!$route.meta.vuecommerce" /> 

      <component v-if="$route.meta.vuecommerce" :is="'vuecommerce'">
        <router-view />
      </component>

      <div v-if="!$route.meta.vuecommerce" id="app" data-app>
          <router-view name="header"/>
          <div>
              <router-view/>
          </div>
          <router-view name="footer"/>
      </div>
    
    </div>
</template>

<script>
  export default {}
</script>

