import { getToken } from '../utils/auth' // get token from cookie

const buttonStyle = "font-weight:bold;color:#52D400;font-weight:bold:z-index:100000;background-color:white;border:2px solid #52D400;padding:3px;bottom:0px;left:0px;position:relative";
const buttonStylePage = "font-weight:bold;color:#52D400;font-weight:bold;z-index:100000;background-color:white;border:2px solid #52D400;position:fixed;top:10px;left:5px;padding:3px;";
const borderStyle = "1px dotted #52D400";


function buildVcItem(el, binding, vnode)
{
    const value = binding.value;

    if(el.classList.contains('vcintem')) {
        return
    }

    if (!getToken() || (!value.id && !value.type)) {
        return
    }
    
    el.style.border = borderStyle;
    var btn = document.createElement("BUTTON");
    btn.id = 'btn_' + binding.value.type + '_' + binding.value.id;
    if(document.getElementById(btn.id)) {
        return
    }
    btn.style = buttonStyle;
    if ( binding.value.type == 'page') {
        btn.style = buttonStylePage;
    }
    btn.innerHTML = (binding.value.id ? 'Edit ' : 'Create ') + binding.value.type;
    btn.addEventListener("click", (event) => {
        event.preventDefault();
        vnode.context.$root.$emit('vuecommerce-upsert-item', binding.value);
    })
    el.append(btn);
    el.classList.add('vcintem')
    
}

export default {
    deep: true,
    update: async function (el, binding, vnode) {
        buildVcItem(el, binding, vnode)
    },
    inserted: async function (el, binding, vnode) {
        const value = binding.value instanceof Promise ? await binding.value : binding.value;
        if (!value.id) { // maybe observer, call update hook
            return
        }
        buildVcItem(el, binding, vnode)
    },
    // inserted: function (el, binding, vnode) {
    //     if (!getToken()) {
    //         return
    //     }
    //     el.style.border = borderStyle;
    //     var btn = document.createElement("BUTTON");
    //     btn.style = buttonStyle;
    //     if ( binding.value.type == 'page') {
    //         btn.style = buttonStylePage;
    //     }
    //     btn.innerHTML = "Edit " + binding.value.type;
    //     btn.addEventListener("click", (event) => {
    //         event.preventDefault();
    //         vnode.context.$root.$emit('vuecommerce-upsert-item', binding.value);
    //     })
    //     el.append(btn);
    // }
}