<template>
  <v-container
    fluid
    grid-list-xl
    fill-height>
    <v-layout
      justify-center
      align-center>
      <v-flex
        xs12
        sm8
        md4>
        <material-card
          color="orange"
          title="VueCommerce - Forgot Password"
          text="Please insert your username"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md12>
                  <v-text-field
                    v-model="forgotForm.username"
                    :rules="validators.username"
                    prepend-icon="mdi-account"
                    name="username"
                    label="Username"
                    type="text"
                  />
                </v-flex>

                <v-flex
                  xs12
                  text-right>
                  <v-btn
                    color="orange"
                    :disabled='loading'
                    :loading='loading'
                    class="mx-0 font-weight-light"
                    @click="forgot"
                  >Send</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import validators from '@/utils/validators'
import { forgotPass } from '../../api/auth'

export default {
  data: () => ({
    loading: false,
    forgotForm: {
      username: ''
    },
    validators
  }),

  methods: {
    async forgot () {
      this.loading = true
      try {
        await forgotPass({ username: this.forgotForm.username })
        this.$root.$emit('openSnackbar', { message: 'Password reset requested. Please check your e-mail. ', color: 'success'})
      } catch (e) {
        this.$root.$emit('openSnackbar', { message: 'An error occurred during password reset request. ', color: 'red'})
      }
      this.loading = false
    }
  }
}
</script>
