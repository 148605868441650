<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex md12>
        <app-table
          ref="articleTable"
          :config="config"
          :api-getlist="getlist"
          :filters="{type:'article'}"
          :hidden-fields="{type:'article'}"
          title="List Articles"
          upsertTitle="Upsert Article"
          upsertMaxWidth="1000"
          :sortableDatatable="true"
          @sorted="sorted"
        >


      <!--template slot="upsert">
        <app-upsert
          v-if="openUpsert"
          :title="upsertTitle || config.title || title || entityName"
          :item="item || hiddenFields"
          :config="config"
          :subtitle="subtitle"
          :apiUpsert="apiUpsert"
          :apiGetdetail="apiGetdetail"
          modal
          @saved="upsertSaved"
          @close="openUpsert=false" />
      </template-->

        </app-table>


      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import config from './config_index.js'
const { getList } = require('../../api/base_entity')


export default {
  data () {
    return { 
      config,
      items: []
    }
  },
  methods: {
    async getlist (entityName, filters, pagination) {
      const data = await getList(entityName, filters, pagination)
      this.items = data.data
      return { data: data.data, tot: data.tot }
    },
    async sorted(event) {
      const movedItem = this.items[event.oldIndex]
      const replacedItem = this.items[event.newIndex]
      await upsert("item", {id:movedItem.id, weight:replacedItem.weight})
      await upsert("item", {id:replacedItem.id, weight:movedItem.weight})
      const data = await getList('item', {type:'article'}, {'rowsPerPage': 10,'sortBy': 'weight','descending': false})
      this.items = data.data
    },
  }
}
</script>
