<template>
    <component :is="layout" class="vuecommerce">
      <router-view />
    </component>
</template>

<script>
// const defaultLayout = 'vuecommerce-no-sidebar'
const defaultLayout = 'vuecommerce-default'
export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  created () {
    // nothing defined here (when this.$route.path is other than "/")
    // console.log(this.$route, this.$route.meta.layout);
  },
  updated () {
    // something defined here whatever the this.$route.path
    // console.log(this.$route, this.$route.meta.layout);
  }
}
</script>