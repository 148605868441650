
<template>
  <v-container
    fluid
    grid-list-xl
    fill-height>
    <v-layout
      justify-center
      align-center>
      <v-flex
        xs12
        sm8
        md4>
        <material-card
          :color="cardColor"
          :text="message"
          title="VueCommerce - Login">
          <v-form
            ref="form"
            v-model="validForm">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md12>
                  <v-text-field
                    v-model="loginForm.username"
                    :rules="[v => !!v || 'Username is required']"
                    prepend-icon="mdi-account"
                    name="login"
                    label="Login"
                    type="text"
                    required
                  />
                </v-flex>
                <v-flex
                  xs12
                  md12>
                  <v-text-field
                    id="password"
                    v-model="loginForm.password"
                    :append-icon="
                      passwordType == 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="passwordType"
                    :rules="[v => (v && v.length >= 8) || 'The password must have at least 8 characters']"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    required
                    @click:append="showPwd"
                  />
                </v-flex>

                <v-flex
                  xs6
                  text-left>
                  <br>
                  <a @click="forgotPass">Forgot password</a>
                </v-flex>

                <v-flex
                  xs6
                  text-right>
                  <v-btn
                    :disabled="!validForm"
                    color="primary"
                    class="mx-0 font-weight-light"
                    @click="login"
                  >Login</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {login} from '../../api/auth'

export default {
  data: () => ({
    validForm: true,
   
    loginForm: {
      username: '',
      password: ''
    },
    passwordType: 'password',
    message: 'Please insert your credentials',
    cardColor: 'primary'
  }),
  methods: {
    login () {
      if (!this.$refs.form.validate()) {
        return
      }
      
      // login(this.loginForm)

      this.$store
        .dispatch('user/login', this.loginForm)
        .then(user => {
          this.cardColor = 'success'
          const path = this.$route.query.redirect || '/vuecommerce'
          this.$router.push({ path })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.message = 'Authentication failed!'
          this.cardColor = 'error'
        })
    },
    forgotPass () {
      this.$router.push({ path: '/vuecommerce/forgot-pass' })
    },
    showPwd () {
      this.passwordType =
        this.passwordType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
