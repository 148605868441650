<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>

    <v-flex>
      <app-upsert
        :config="config"
        :item="protocol"
        :title="`${protocol.name} / ${protocol.status}`"
        :subtitle="`${protocol.protocol} - ${protocol.sponsor}`"
        readonly
        class="mb-5"
        @saved="saved">

        <template slot="actions">
          <v-badge
            color='green' overlap :value="nDrugs"
            :content="nDrugs">
            <v-btn
              color="primary"
              :to="`/protocol/${protocol.id}/drugs`">
              <v-icon
                small
                class="mr-1">mdi-pill</v-icon>  Drugs
            </v-btn>
          </v-badge>

          <v-badge
            :content="nSites" color='green' overlap :value="nSites">
            <v-btn
              class="ml-2"
              color="primary"
              :to="`/protocol/${protocol.id}/sites`">
              <v-icon
                class="mr-1">mdi-home</v-icon> Sites
            </v-btn>
          </v-badge>

          <!-- <v-btn
            v-if="protocol.status === 'INIT'"
            color="success"
            class="ml-2"
            :disabled="!nDrugs || !nSites"
            @click="confirm"
            small>
            <v-icon
              small
              class="mr-1">mdi-check</v-icon> Confirm
          </v-btn> -->

          <v-btn
            v-if='$can("upsert", "request")'
            class="mx-1 font-weight-light"
            color="success"
            :to="`/protocol/${protocol.id}/request/new`"
            :disabled="!nDrugs || !nSites">
            <v-icon
              small
              class="mr-1">mdi-plus</v-icon> Add Request
          </v-btn>

          <v-btn
            v-if='$can("read", "request")'
            class="mx-1 font-weight-light"
            color="success"
            :to="`/protocol/${protocol.id}/request`"
            :disabled="!nDrugs || !nSites">
            <v-icon
              small
              class="mr-1">mdi-list</v-icon> Request list
          </v-btn>          

          <v-btn
            class="mx-1"
            @click='toggleActive'
            :disabled="!nDrugs || !nSites"
            :color="protocol.status==='CLOSED' || protocol.status === 'INIT' ?'green':'danger'">
            <v-icon
              class="mr-1">mdi-{{protocol.status==='CLOSED' || protocol.status === 'INIT' ?'check':'close'}}</v-icon> {{protocol.status === 'CLOSED' || protocol.status === 'INIT' ?'Enable':'Disable'}}
          </v-btn>

                    

        </template>
      </app-upsert>

    </v-flex>

  </v-container>
</template>

<script>
import { getDetail, upsert } from '../../api/base_entity'

// import { getList as getPREList, upsert, getDetail, uploadPdf, downloadPdf, nextCin, notifyPregnancy } from '@/api/pregnancy'
// import validators from '@/utils/validators'
// import { getList as getSites } from '@/api/site'
// import { getList as getExternalUser } from '@/api/external_user'
// import { emailBody, emailSubject } from '@/utils/pre_mail'

import config from './config_index.js'

export default {
  data () {
    return {
      config,
      itemId: null,
      openDrugs: false,
      openSite: false,
      openStorage: false,
      openDisposal: false,
      hiddenFields: {},
      protocol: {},
      nDrugs: 0,
      nSites: 0
    }
  },
  async created () {
  
  },
  methods: {
    saved (item) {
      this.itemId = Number(item.assetId)
      this.hiddenFields = { protocol_id: this.itemId }
    },
    confirm () {
      const protocol = Object.assign({}, this.protocol)
      protocol.status = 'CONFIRMED'
      upsert('protocol', protocol)
      this.protocol.status='CONFIRMED'
      // this.$router.reload()
    },
    loaded (protocol) {
      this.protocol = protocol
    },
    async toggleActive () {
      let ret = true
      const protocol = Object.assign({}, this.protocol)
      if (protocol.status === 'ACTIVE') {
        ret = await this.$root.$confirm.open('Disable protocol', `Are you sure to disable the protocol <u>${protocol.name}</u>?`)
      } else {
        ret = await this.$root.$confirm.open('Enable protocol', `Are you sure to enable the protocol <u>${protocol.name}</u>?`)
      }
      if (!ret) return
      protocol.status = protocol.status === 'ACTIVE' ? 'CLOSED' : 'ACTIVE'
      ret = await upsert('protocol', protocol)
      if (ret && ret.data.status) {
        this.protocol.status = ret.data.status
      }
    }
  }
}
</script>