<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex md12>
        <app-table
          :config="config"
          :api-getlist="getlist"
          :filters="{type:'content'}"
          :hidden-fields="{type:'content'}"
          title="List Contents"
          upsertTitle="Upsert Content"
          upsertMaxWidth="1000"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import config from './config_index.js'
const { getList } = require('../../api/base_entity')

export default {
  data () {
    return { config }
  },
  methods: {
    async getlist (entityName, filters, pagination) {
      const data = await getList(entityName, filters, pagination)
      return { data: data.data, tot: data.tot }
    }
  }
}
</script>
