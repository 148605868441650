<template>
    <div class="wrapper" v-vcitem="page">
        <div class="page-header page-header-mini">
            <parallax class="page-header-image" :style="'z-index:1;filter: brightness(70%);'"  v-vcbgimage:image1="item" >
            </parallax>
        </div>
        <div class="section" v-vcitem="item">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                      
                        <img class="d-block img-raised" style="height_:300px" v-vcimage:image2="item">
                         
                        <p class="blockquote blockquote-primary" v-html="item.description2" v-vcdescription:description2="item">
                            <br>
                        </p>

                    </div>
                    <div class="col-md-6 ml-auto mr-auto">
                        <h2 class="title"> {{item.title}} </h2>
                        <h5 class="category">{{item.subtitle}}</h5>
                        <h2 class="main-price">{{item.price}} &euro; {{item.price_descr}} </h2>
                                <p v-html="item.description1"  v-vcdescription:description1="item">
                                </p>


                                <a href="/#/contact" class="btn btn-primary btn-round btn-lg btn-block">Contact US</a>

                                <br><br>

                                    <img class="d-block img-raised" style="height_:300px" v-vcimage:image3="item">

                        <!--div class="row pick-size">
                            <div class="col-lg-6 col-md-8 col-sm-6">
                                <label>Select color</label>
                                <br>
                                <el-select v-model="filters.color" class="select-default">
                                    <el-option class="select-default" value="1" label="Black"></el-option>
                                    <el-option class="select-default" value="2" label="Gray"></el-option>
                                    <el-option class="select-default" value="3" label="White"></el-option>
                                </el-select>
                            </div>
                            <div class="col-lg-6 col-md-8 col-sm-6">
                                <label>Select size</label>
                                <br>
                                <el-select v-model="filters.size" class="select-default">
                                    <el-option class="select-default" value="1" label="Small"></el-option>
                                    <el-option class="select-default" value="2" label="Medium"></el-option>
                                    <el-option class="select-default" value="3" label="Large"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                            <n-button type="primary" class="mr-3">
                                Add to Cart &nbsp;
                                <i class="now-ui-icons shopping_cart-simple"></i>
                            </n-button>
                        </div-->
                    </div>
                </div>
                <div class="section">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto text-left mr-5" v-html="item.description3" v-vcdescription:description3="item">
                            <!--h2 class="title">How to wear it</h2>
                            <h4 class="description">You need more information? Check what other persons are saying about
                                our product. They are very happy with their purchase.</h4-->
                        </div>
                    </div>
                    <div class="section-story-overview">
                        <div class="row">

                            <div class="col-md-4 ml-auto mr-auto">
                                <div class="image-container image-left"  v-vcbgimage:image4="item">
                                    <!-- First image on the left side -->
                                    <!-- <p class="blockquote blockquote-primary" v-html="item.description3" v-vcdescription:description3="item">
                                    </p> -->
                                </div>
                                <!-- Second image on the left side of the article -->
                                <!-- <div class="image-container" style="height:300px" v-vcbgimage:image4="item"></div> -->
                            </div>


                             <div class="col-md-4 ml-auto mr-auto">
                                 <div class="image-container image-right" v-vcbgimage:image5="item"></div>
                             </div>
                            
                        </div>

                         <div class="row">
                               <div class="col-md-4 ml-auto mr-auto">
                                 <div class="image-container image-right" v-vcbgimage:image6="item"></div>
                             </div>
                            
                         </div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="section related-products" data-background-color="black">
            <div class="container">
                <h3 class="title text-center">{{page.title2}}</h3>
                <div class="row">
                    <div class="col-sm-6 col-md-3" v-for="item in interested" :key="item.id">
                        
                        <a :href="'/#/product/' + item.id" >
                        <card type="product">

                            <!-- <img slot="image" style="max-height:180px;" class="img rounded" v-vcimage:image1="item" /> -->

                            <div slot="image" class="image-container" style="height:220px;width:100%;background-size:cover;" v-vcbgimage:image1="item"></div>

                            <h6 class="category text-danger">{{item.title}}</h6>
                            <h4 class="card-title">
                                <a class="card-link">{{item.subtitle}}</a>
                            </h4>
                            <div class="card-description text-left">
                               <div style="padding:5px;" v-html="item.description" />
                            </div>
                            <div class="card-footer">
                                <div class="price-container text-center">
                                    <span class="price text-center">{{item.price}} €{{item.price_descr}}</span>
                                </div>
                             
                            </div>
                        </card>
                        </a>
                    </div>
                  
                </div>
            </div>
        </div>
        <footer class="footer footer-big footer-white">
            <div class="container">
                <hr />
                <div class="copyright">
                    Copyright &copy;
                    {{year}} Depalop All Rights Reserved.
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
  import { Card, Button, Collapse, CollapseItem } from '@/components';
  import { Carousel, CarouselItem, Select, Option, Tooltip } from 'element-ui'
  const { getDetail, getList } = require('@/api/base_entity')

  export default {
    name: 'product-page',
    bodyClass: 'product-page',
    components: {
      Card,
      Collapse,
      CollapseItem,
      [Button.name]: Button,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
    },
    data() {
      return {
        page: {},
        filters: {
          color: '',
          size: ''
        },
        year: new Date().getFullYear(),
        id: null,
        item: {},
        interested: []
      }
    },
    mounted() {
        this.$root.$on('vuecommerce-update-item', this.updateItems)
        this.updateItems()
    },
    watch:{
        $route (to, from){
            this.updateItems()
        }
    },
    destroyed() {
        this.$root.$off('vuecommerce-update-item')
    },
    methods: {
        image(image) {
            if (image) {
                return process.env.VUE_APP_BASE_API + '/images/' + image.file
            }
            return
        },
        updateItems() {
            getDetail('item', this.$route.params.product_id).then( data => {
                this.item = data
                getList('item', {type:'article', parent_id:this.item.parent_id}).then( data => {

                    // shuffle articles
                    const n = 4;
                    this.interested = data.data
                    .map(x => ({ x, r: Math.random() }))
                    .sort((a, b) => a.r - b.r)
                    .map(a => a.x)
                    .slice(0, n);

                })
            })
            getList('item', {type:'page', name:'article'}).then( data => {
                  this.page = data.data[0] || {}
            })
            
        },
    }
  }
</script>
<style>
</style>
