<template>
  <v-container fluid grid-list-xl fill-height>
    <v-layout justify-center align-center>
      <v-flex xs12 sm8 md4>
        <material-card
          color="orange"
          title="VueCommerce - Create Password"
          text="Choose your PASSWORD"
        >
          <v-form ref="form" v-model="validForm">
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="forgotForm.email"
                    prepend-icon="mdi-account"
                    name="email"
                    label="Email"
                    type="text"
                    disabled
                    :rules="[validators.email()]"
                  ></v-text-field>
                </v-flex>

                 <v-flex xs12 md12>
                  <v-text-field
                    autocomplete="false"
                    v-model="forgotForm.newpass"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      passwordType1 == 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="showPwd1"
                    name="password"
                    label="Password"
                    id="password"
                    :type="passwordType1"
                    required
                    :rules="[validators.password]"
                  >
                  </v-text-field>
                </v-flex>


                <v-flex xs12 md12>
                  <v-text-field
                    autocomplete="new-password"
                    v-model="forgotForm.confirmpass"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      passwordType2 == 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="showPwd2"
                    name="password"
                    label="Confirm Password"
                    id="password"
                    :type="passwordType2"
                    required
                    :rules="[v => validators.confirm_password(v, forgotForm.newpass)]"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 text-xs-right>
                  <v-btn
                    color="orange"
                    class="mx-0 font-weight-light"
                    @click="confirm_forgot"
                    :loading='loading'
                    :disabled="!validForm || loading"
                    >Confirm</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import validators from "@/utils/validators"
import { confirmForgotPass } from '../../api/auth'

export default {
  data: () => ({
    forgotForm: {},
    loading: false,
    validForm: true,
    passwordType1: 'password',
    passwordType2: 'password',
    validators
  }),
  mounted() {
    this.forgotForm.restore_pass_token = this.$route.query.restore_pass_token
    this.forgotForm.email = this.$route.query.email;
  },
  methods: {
    async confirm_forgot() {
      this.loading = true
      try {
        await confirmForgotPass(this.forgotForm)
        this.$root.$emit("openSnackbar", {
          message: "The new password has been stored correctly!",
          color: "success"
        })

        this.$router.push({ path: "/login" })
      } catch(e) {
        this.$root.$emit("openSnackbar", {
          message: "An error occurred during the new password confirmation: " + e && e.message,
          color: "error"
        })
      }
      this.loading = false
    },
    showPwd1 () {
      this.passwordType1 =
        this.passwordType1 === 'password' ? 'text' : 'password'
    },
    showPwd2 () {
      this.passwordType2 =
        this.passwordType2 === 'password' ? 'text' : 'password'
    }
  },
 
};
</script>
