<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap>
      
      <v-flex sm6 xs12 md3 lg3>
        <material-stats-card
          to="/vuecommerce/page"
          color="primary"
          icon="mdi-book-open-page-variant"
          title="Pages"
          :value="''+dashboardData.page"
          sub-icon="mdi-view-list"
          sub-text="Go to list..."
        />
      </v-flex>

       <v-flex sm6 xs12 md3 lg3>
        <material-stats-card 
          to="/vuecommerce/content"
          color="primary"
          icon="mdi-table-of-contents"
          title="Contents"
          :value="''+dashboardData.content"
          sub-icon="mdi-view-list"
          sub-text="Go to List..." />
      </v-flex>

      <v-flex sm6 xs12 md3 lg3>
        <material-stats-card 
          to="/vuecommerce/group"
          color="primary"
          icon="mdi-group"
          title="Groups"
          :value="''+dashboardData.group"
          sub-icon="mdi-view-list"
          sub-text="Go to List..." />
      </v-flex>

      <v-flex sm6 xs12 md3 lg3>
        <material-stats-card 
          to="/vuecommerce/article"
          color="primary"
          icon="mdi-tag"
          title="Articles"
          :value="''+dashboardData.article"
          sub-icon="mdi-view-list"
          sub-text="Go to List..." />
      </v-flex>
      

      <v-flex md12 lg12 v-if="$can('read', 'log' || 'all' )">
        <material-card color="secondary" title="Logs">
          <v-data-table
            :headers="headersLogs"
            :items="logs"
            :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
            :options.sync='paginationLogs'
            @update:items-per-page="getLogs"
            @update:page='getLogs'            
            :server-items-length="totalLogs"
            item-key="insert_at">
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="font-weight-light left text-primary text--darken-3"
                v-text="header.text"/>
            </template>
            <template
              slot="item"
              slot-scope="{ item }">
              <tr @click="item.expanded = !item.expanded">
                <td v-for="header in headersLogs" :key="header.id">{{ item[header.value] }}</td>
              </tr>

              <tr v-if="item.expanded">
                <td
                  :colspan="headersLogs.length"
                  style="padding:0">

                  <v-list
                    two-line
                    dense
                    dark>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Request</v-list-item-title>
                        <v-list-item-subtitle>{{ item.request }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>  Response </v-list-item-title>
                        <v-list-item-subtitle>{{ item.response }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                </td>
              </tr>

            </template>

          </v-data-table>
        </material-card>
      </v-flex>

     
    </v-layout>
  </v-container>
</template>

<script>
import { getList } from "../api/base_entity";
import { getLogRouteList } from "../api/log";
import { normalizeFilters } from "../utils/filters";

export default {
  data() {
    return {
      loading: false,
      logs: [],
      totalLogs: 0,
      totalAdverseEvents: 0,
      searchAccount: '',
      dashboardData: {
        pages: '-',
        groups: '-',
        items: '-',
        contents: '-'
      },
      paginationLogs: {
        rowsPerPage: 5,
        page: 1,
        sortBy: "insert_at",
        descending: true,
      },
      searchAutocomplete: {
        'account_id':''
      },
      filtersLogs: {
        'account_id': {
          type:'autocomplete',
          value: undefined,
          replace: 'VALUE',
          placeholder: 'Search',
          item_value:'id',
          item_text: 'email',
          list: [],
        },
        route: {
          type:'autocomplete',
          value: undefined,
          // replace: 'VALUE%',
          placeholder: 'Search',
          list: []
        },
        http_verb: {
          type:'list',
          value: undefined,
          placeholder: 'Choose',
          list: ['POST', 'PUT', 'DELETE']
        },
      },
      headersLogs: [
        {
          sortable: false,
          text: "Insert at",
          value: "insert_at"
        },
        {
          sortable: false,
          text: "Account",
          value: "user",
        },
        {
          sortable: false,
          text: "Route",
          value: "route",
        },
        {
          sortable: false,
          text: "Http method",
          value: "http_verb",
        },
      ],
    };
  },
  created() {
  },
  computed: {
  },
  watch: {
    searchAutocomplete: {
      async handler(val, oldVal) {
        if (val['account_id']) {
          const data = await getList('account', {'email':'%'+val['account_id']+'%'})
          this.filtersLogs['account_id'].list = data.data
        }
        if (val['route']) {
          const data = await getLogRouteList(val['route'])
          this.filtersLogs['route'].list = data.data
        }
      },
      deep: true
    },
    paginationLogs: {
      handler(val, oldVal) {
        if (oldVal.totalItems){
          this.getLogs();
        }
      },
      deep: false
    },
    filtersLogs: {
      handler(val) {
        this.paginationLogs.page = 1;
        this.getLogs();
      },
      deep: true
    },
  },
  mounted() {
    this.getLogs();
    this.getDashboardData();
  },
  methods: {
    getLogs() {
      this.loading = true;
      const filters = normalizeFilters(JSON.parse(JSON.stringify(this.filtersLogs)));
      if(!filters.http_verb) {
        filters.http_verb = '!=GET'
      };
      getList('log',filters, this.paginationLogs).then(data => {
        this.paginationLogs.rowsPerPage = data.perPage;
        this.paginationLogs.page = data.currentPage;
        this.paginationLogs.totalItems = this.totalLogs = data.tot;

        data.data = data.data.map(item => {
          item.user = `${item.account.name} ${item.account.surname} (${item.account.role})`
          item.expanded = false
          return item
        })

        // data.data.forEach(function(el) { 
        //   el.expanded = false;
        // });
        this.logs = data.data;
        this.loading = false;
      });
    },
    getDashboardData() {
      getList('dashboard_data').then(data => {
        this.dashboardData = data
      })
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
          return text.substring(0, length) + suffix;
      }
      return text;
    },
  }
};
</script>

<style lang="scss">
.dataTable {
  .v-datatable__actions {
    color: black;
    .v-btn__content {
      color: black;
    }
    .v-btn {
      background-color: rgb(241, 241, 241);
    }
  }
 .read td {
    font-size: 14px;
    font-weight: 300;
  }
  .unread td{
    font-weight:bold;
    background:#ffffff;
  }
  tr.lighten-3 th {
      padding: 0px 5px 0px 5px;
  }
}
</style>