<template>
  <v-navigation-drawer
    class='red--text text--light-5'
    id='app-drawer'
    v-model='inputValue'
    persistent
    app
    dark
    mobile-breakpoint="991"
    width="290"
  >
    <v-list>

    <!-- <v-img
      :src="image"
      :gradient="sidebarOverlayGradiant"
      height="100%"> -->
      <v-list-item class='pt-3 pb-5'>
        <v-img src='/img/logo.png' contain />
        <v-list-item-title class="title text-right">
          VueCommerce
        </v-list-item-title>
      </v-list-item>

      <v-divider/>
        <component
          v-for="(link, i) in allowedLinks"
          :is="link.type"
          :key="i"
          v-bind="link"
          avatar
        />
      </v-list>
    <!-- </v-img> -->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from 'vuex'
import links from './menu'

export default {
  data: () => ({
    logo: '/img/logo.png',
    responsive: true,
    links
  }),
  computed: {
    // ...mapState('vuecommerce'),

    // display allowed links only
    allowedLinks () {
      // return links.filter(link => !link.entity || this.$can(link.action, link.entity))
      return links.filter(link => link)
    },
    inputValue: {
      get () {
        return this.$store.state.vuecommerce.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    },
  },
  async mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style lang="scss">
#app-drawer {
  background-color: rgba(47, 47, 47, 0.84);
  .v-list-item {
    border-radius: 4px !important;

    &::before {
      border-radius: 4px !important;
    }
  }
}
</style>
