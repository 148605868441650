<template>
  <v-snackbar
    :color="color"
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    v-model="active"
    :timeout="10000"
    multi-line
  >
    <v-icon
      color="white"
      class="mr-3">
      mdi-bell-plus
    </v-icon>
    {{ message }}
    <template v-slot:action="{ }">
      <v-icon
        size="16"
        @click="active = false">
        mdi-close-circle </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      color: 'primary',
      bottom: true,
      top: false,
      left: false,
      right: true,
      active: false,
      timeout: 10000,
      message: ''
    }
  },
  created () {
    this.$root.$on('openSnackbar', snackbar => {
      this.active = true
      this.message = snackbar.message
      this.color = snackbar.color || 'primary'
    })
  }
}
</script>
