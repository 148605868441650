var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('material-card',{attrs:{"title":_vm.title,"color":"primary"}},[_c('v-form',{ref:"form",attrs:{"autocomplete":"off","role":"presentation"},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-data-table',{attrs:{"items":_vm.images,"footer-props":{
                  'items-per-page-options': [2,4]
                },"items-per-page":4,"headers":[
                  { text: 'Preview', value: 'preview', sortable: false },
                  { text: 'ID', value: 'id', sortable: false },
                  { text: 'Size', value: 'size', sortable: false },
                  { text: 'Width/Height', value: 'dimension', sortable: false },
                  { text: 'Action', value:'action', sortable: false }]},scopedSlots:_vm._u([{key:"item.preview",fn:function(ref){
                  var item = ref.item;
return [_c('v-img',{attrs:{"height":"80","width":"100","eager":true,"src":_vm.fullfile(item.file)},on:{"click":function($event){return _vm.setImage(item)}}})]}},{key:"item.size",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.size / 1024))+" Kb ")]}},{key:"item.dimension",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.width)+" x "+_vm._s(item.height)+" ")]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-adjust")])]}}],null,true)},[_c('v-list',[(item.file.split('.').pop() == 'png')?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.adjust(item, 'background_remove')}}},[_vm._v("Transparent BG")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.adjust(item, 'thumb')}}},[_vm._v("Thumbnail")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.adjust(item, 'crop')}}},[_vm._v("Crop")])],1)],1)],1),_vm._v(" "),_c('a',{attrs:{"flat":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","text-right":""}},[_c('v-btn',{staticClass:"mx-1 font-weight-light",attrs:{"color":"success"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")]),(_vm.$can('upsert', _vm.entityName))?_c('v-btn',{staticClass:"mx-1 white--text",attrs:{"color":"orange"},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" Clear")]):_vm._e(),(_vm.$can('upsert', _vm.entityName))?_c('v-btn',{staticClass:"mx-1 white--text",attrs:{"color":"purple"},on:{"click":function($event){return _vm.pickFile()}}},[_vm._v(" Upload")]):_vm._e()],1)],1),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"id":"file","type":"file","accept":".jpg, .png, .jpeg, application/image"},on:{"change":function($event){return _vm.handleFileUpload()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }