/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import store from '@/store'


// Store functionality
import modules from './modules'

store.registerModule("vuecommerce", {
  modules,
  // plugins: [
  //   (store) => {
  //     const ability = store.getters.ability
  //     return store.subscribe((mutation) => {
  //       switch (mutation.type) {
  //         case 'user/SET_RULES':
  //           ability.update(mutation.payload)
  //           break
  //       }
  //     })
  //   }
  // ]
});


// // Create a new store
// const store = new Vuex.Store({
//   modules,
//   actions,
//   getters,
//   mutations,
//   state,
//   plugins
// })

export default store
