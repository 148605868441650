import request from './request'

export function getLogRouteList (val) {
  return request({
    url: '/log/route',
    headers: {
      'X-filters': JSON.stringify({val})
    }
  })
}
