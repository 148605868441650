export default {
  'entityName': 'order',
  'pagination': {
    'rowsPerPage': 10,
    'page': 1,
    'sortBy': 'id',
    'descending': true
  },
  'fields': [
    {
      value: 'name',
      text: 'Name',
      'type': 'text',
      'table': {
        'sortable': true,
        'filter': true
      },
      'validators': [
        'required',
        'email'
      ]
    },
    
    {
      value: 'description',
      text: 'Description',
      'type': 'text',
      'validators': [
        'required'
      ]
    },
    {
      value: 'price',
      text: 'Price',
      'type': 'number',
      'table': {
        'sortable': true,
        filter: true
      },
      'validators': [
        'required'
      ]
    },
    {
      value: 'status',
      text: 'Status',
      'type': 'list',
      'table': {
        'sortable': true,
        'filter': true
      },
      'list': [
        'Active',
        'Disabled'
      ],
      'validators': [
        'required'
      ]
    }
  ],
  actions: ['upsert']
}
