import request from './request'

export function getList (entity, filters = {}, pagination = {}) {
  return request({
    url: '/' + entity,
    method: 'get',
    headers: {
      'x-filters': JSON.stringify(filters),
      'x-pagination': JSON.stringify(pagination)
    }
  })
}


export function getDetail (entity, item) {
  return request({
    url: '/' + entity + (item && item.id ? '/' + item.id  : ''),
    method: 'get'
  })
}


export function put (entity, id, data) {
  const method = 'put'
  const url = '/' + entity + '/' + id
  return request({
    url,
    method,
    data
  })
}

export function get (url) {
  const method = 'get'
  return request({
    url,
    method
  })
}

export function upsert (entity, data) {
  let method = 'post'
  let url = '/' + entity
  if (data.id) {
    method = 'put'
    url = '/' + entity + '/' + data.id
  }
  return request({
    url,
    method,
    data
  })
}

export function remove (entity, item) {
  return request({
    url: '/' + entity + '/' + item.id,
    method: 'delete'
  })
}


export function uploadImage (data) {
  return request({
    url: '/item/image/' + data.get('id'),
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}