<template>
  <v-list-item
    :to="to" link exact
    :active-class="color">
    <v-list-item-action>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-title v-text="text" />
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    to: { type: String, default: '' },
    text: { type: String, default: '' },
    icon: { type: String, default: '' }
  },
  computed: mapState('app', ['image', 'color'])
}
</script>
<style lang="stylus">

</style>