<template>
  <v-app class="vuecommerce" style="height:0px">
    <v-main>
      

      <v-dialog
        v-model="openArticle"
        :max-width="1000">
        <app-upsert
          :title="title"
          :item="item" 
          :config="configArticle"
          modal
          @saved="updateItems"
          @close="openArticle=false">
        </app-upsert>
      </v-dialog>


      <v-dialog
        v-model="openGroup"
        :max-width="1000">
        <app-upsert
          :title="title"
          :item="item" 
          :config="configGroup"
          modal
          @saved="updateItems"
          @close="openGroup=false">
        </app-upsert>
      </v-dialog>


      <v-dialog
        v-model="openPage"
        :max-width="1000">
        <app-upsert
          :title="title"
          :item="item" 
          :config="configPage"
          modal
          @saved="updateItems"
          @close="openPage=false">
        </app-upsert>
      </v-dialog>


      <v-dialog
        v-model="openContent"
        :max-width="1000">
        <app-upsert
          :title="title"
          :item="item" 
          :config="configContent"
          modal
          @saved="updateItems"
          @close="openContent=false">
        </app-upsert>
      </v-dialog>

      <app-images-modal :open="openImages" :data="data" @close="openImages=false" @choosedImage="choosedImageEvt"/>
      <app-description-modal :open="openDescription" :data="data" @close="openDescription=false" @changedDescription="changedDescriptionEvt"/>

    </v-main>

    <core-confirm ref="confirm" />

  </v-app>
</template>

<script>
// const defaultLayout = 'vuecommerce-no-sidebar'
import configArticle from './views/article/config_index.js'
import configGroup from './views/group/config_index.js'
import configPage from './views/page/config_index.js'
import configContent from './views/content/config_index.js'
import { getDetail } from './api/base_entity.js'

const defaultLayout = 'vuecommerce-default'
export default {
  name: 'vuecommerce-global-item',
  mounted() {
    this.$root.$on('vuecommerce-upsert-item', this.upsertItem);
    this.$root.$on('vuecommerce-upsert-image', this.upsertImage);
    this.$root.$on('vuecommerce-upsert-description', this.upsertDescription);
    this.$root.$confirm = this.$refs.confirm
  },
  destroyed () {
    this.$root.$off('vuecommerce-upsert-item');
    this.$root.$off('vuecommerce-upsert-image');
    this.$root.$off('vuecommerce-upsert-description');
  },
  data() {
    return {
      configGroup,
      configArticle,
      configPage,
      configContent,
      openPage: false,
      openGroup: false,
      openArticle: false,
      openContent: false,
      openImages: false,
      openDescription: false,
      item: {},
      data: {},
      title:'',
      choosedImage:null,
      changedDescription:null
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  created () {
    // nothing defined here (when this.$route.path is other than "/")
    // console.log(this.$route, this.$route.meta.layout);
  },
  updated () {
    // something defined here whatever the this.$route.path
    // console.log(this.$route, this.$route.meta.layout);
  },
  methods: {
    async choosedImageEvt(v) {
      this.choosedImage = v
      this.item = await getDetail('item', {id: this.item.id})
    },
    async changedDescriptionEvt(v) {
      this.changedDescription = v
      this.item = await getDetail('item', {id: this.item.id})
      this.updateItems(this.item);
    },
    async upsertItem(item) {

      // update item
      this.item = await getDetail('item', {id: item.id})

      if(item.type=='group') {
        this.title = 'Edit GROUP'
        this.openGroup = true;
      }
      if(item.type=='article') {
        this.title = 'Edit ARTICLE'
        this.openArticle = true;
      }
      if(item.type=='page') {
        this.title = 'Edit PAGE'
        this.openPage = true;
      }
       if(item.type=='content') {
        this.title = 'Edit Content'
        this.openContent = true;
      }

    },
    upsertImage(data) {
      this.data = data
      this.openImages = true;
    },
    upsertDescription(data) {
      this.data = data
      this.openDescription = true;
    },
    // selectImageField(item) {
    //    this.upsertImage(item)
    //    console.log("change image : ", item)
    // },
    async updateItems(data) {
      this.$root.$emit('vuecommerce-update-item', data.item)
    }
  }
}
</script>


<style >
  /* .v-dialog {
    align-items: flex-start;
    margin-top: -30vh;
  } */
</style>